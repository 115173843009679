<template>
      <div class="toast" id="toast">
        <div class="toast-header">
          <strong class="me-auto">Link copiato correttamente</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast"></button>
        </div>
      </div>
</template>

<script>
export default {
  name: "Toast"
}
</script>

<style scoped>
.toast {
  top:80%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, 0px);
  z-index: 9999;
}
</style>