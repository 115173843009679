export const en = {
    menu: [
        "Home",
        "Prehistoric circuit",
        "Vollein",
        "The archaeological area",
        "Petroglyphs",
        "Necropolis",
        "Finds",
        "Credits"
    ],
    home: {
        title: "A PREHISTORICAL STORY",
        sub1: "An Interreg project for the development of prehistoric sites in the Aosta Valley",
        sub2: "Slow steps through history to learn more about past millennia.",
        home: "Prehistoric sites prove human have settled in the Aosta Valley since the 7th millennium BC. The harsh mountain environment has caused the slow decay of the man-made structures and objects making archaeologists work increasingly complex. The presence of archaeological evidence from when humans first settled at Vollein often escapes those who travel to this Alpine region." +
            "The “Discover to promote / Découvrir pour promouvoir“ project developed within the framework of the Interreg V-A Italy-France Alcotra 2014-2020 cross-border cooperation program, aims to share common heritage and enhance the importance and uniqueness of archaeological finds." +
            "The Agency for Cultural Heritage has therefore has initiated and promoted actions to safeguard and enhance some of the most significant Aosta Valley prehistorical sites, through their inclusion in the Aosta Valley Prehistorical Circuit. " +
            "Learning about the history of these sites allows visitors to develop greater respect and understanding of this amazing region."
    },
    historyView: {
        title: "Pre-historical millennia ",
        par1: "Breathe the air of this unique site and immerse yourself in the stories that only the earth can reveal...",
        par2: "Located on a rocky hillside dominating the central valley where the Dora Baltea river flows, the Vollein Archaeological Site shows evidence of human activity from between the 5th and 2nd millennium BC. \n" +
            "The area was originally used for funeral rituals but was later used with no clear purpose.\n" +
            "The archaeological artifacts discovered during excavations show early craftsmanship and exchanges with people who lived in the surrounding areas, providing glimpses of the daily life of local inhabitants. \n" +
            "The ceramic, metal, flint and hyaline quartz objects found in the layers of soil which sealed the necropolis testify to human presence at Vollein during the second half of the 5th millennium BC. (Middle-Recent Neolithic period) and the first half of the 2nd millennium BC. (Middle Bronze Age).\n",
        par3: "",
        par4: "",
        par5: "",
        par6: "",
        par7: "",
        par8: "",
        par9: "Vollein Archeological Site: Pre-historical millennia",
        par10: "The archaeological site of Vollein : Between the millennia of prehistory",
        par11: "Around 400 m from the village of Vollein, a rocky hillside on the south-facing side of the mountain dominates the central valley and looks down on the winding Dora Baltea river. The prehistoric site on its summit was discovered in1968 and since then has been the subject of archaeological excavations and surveys that have brought to light different periods of human activity from the 5th to the 2nd millennium BC.\n" +
            "The area was originally used for funeral rituals but was later used with no clear purpose. North-west of the Necropolis there are numerous petroglyphs, although they are subject to various interpretations, they were likely made throughout the millennia when the site was inhabited. North of the Necropolis, there are a series of other engravings, as well as traces of human activity that date to the Middle Bronze Age (2nd millennium BC).",

    },
    vollein: {
        title: "THE VOLLEIN ARCHAEOLOGICAL SITE ",
        sub1: "Prehistorical Millenia",
        par1: "The Vollein Archaeological Site was officially discovered in 1968, when it was reported to the Agency for Cultural Heritage of the Aosta Valley by the then President of the Société Valdôtaine de Préhistoire et d'Archéologie, Damien Daudry." +
            "The importance of this site was evident from the initial inspections and numerous excavations followed until 1994, under the direction of Dr. Rosanna Mollo and Franco Mezzena." +
            "The site is located in an optimal position on a rocky hillside, mid-level on the slope of the central valley facing south, as demonstrated by the use of the area since Prehistoric times." +
            "Numerous tombs were discovered during the excavations, which constitute a Necropolis from the second half of the 5th millennium BC. (Middle-Late Neolithic). A large rocky surface covered in engravings which has engravings such as cup marks, linear signs and possible foot shapes was also discovered, partly linked to the use of the Necropolis but probably also to the sites long periods of settlement. Archaeological surveys carried out in the surrounding area have brought to light a number of finds in the layers above the burial sites, which prove the site was inhabited until the 2nd millennium BC. (Middle Bronze Age)." +
            "Work carried out as part of the “Discover to promote / Découvrir pour promouvoir“ project include a series of interventions on the tomb structures, arrangement and analysis of the documentation produced during excavations, cataloguing and preliminary study of the archaeological finds and the creation of shared information both on site and online (texts, videos and 3D reconstructions).",
        didascalia: "Audio/visual work by Økapi dedicated to the Vollein Necropolis\n"
    },
    necropolisView:{
        title: "The Necropolis",
        par1: "Listen to the silence, which contains the memory of those who lived here long ago.",
        par2: "In this valley, numerous burials were carried out in the necropolis around the second half of the 5th millennium BC. The tombs structure is Chamblandes lithic cist and is characterized by five stone slabs: four placed on their sides to form a rectangular case and one large horizontal slab that acts as a cover. Although partially submerged, part of the structure remained visible to allow burial rituals to be performed.\n" +
            "During these burial rituals, bodies were generally placed in a foetal position, lying on their left side or facing up. Sometimes multiple bodies were placed in the same grave.\n" +
            "Rare ornamental objects testify to travel and exchanges between the Mediterranean population and populations who lived beyond the Alps: Swiss sites also show signs of similar comparisons and connections, as is the case of the shell bracelet Glycymeris sp. found on the arm of the individual placed in Tomb 31.\n",
        par3: "",
        par4: "Vollein - Necropolis",
        par5: "Vollein Archeological Site: Necropolis   ",
        par6: "Since the 5th millennium BC, the rocky hillside that dominates the central valley has been home to a Necropolis with over 50 tombs. The excavated area covers approx. 30 m. east/west and approx. 20 m. north/south.\n" +
            "\n" +
            "The burial sites brought to light by archaeologists are Chamblandes type lithic cist tombs, a type of burial structure that was widespread among the Neolithic people in the western Alps and the Swiss Plateau Suisse up to the Rhine. \n" +
            "During these burial rituals, bodies were generally placed in a foetal position, lying on their left side or facing up. Sometimes multiple bodies were placed in the same grave. \n" +
            "\n" +
            "Rare ornamental objects testify to travel to and exchanges between the Mediterranean population and populations who " +
            "lived beyond the Alps: Swiss sites also show signs of similar comparisons and connections. A few metres north-west of the " +
            "burial area there is a rocky area covered in petroglyphs, more than likely related, at least in part, to the Necropolis.\n",
        par7: "Tomb 31",
        par8: "Tomb 31 is part of the Vollein Necropolis (Quart, Aosta). It is a Chamblandes type lithic cist tomb, a common burial structure among the Neolithic people in the western Alps and the Swiss Plateau Suisse up to the Rhine from the 5th millennium BC onwards. (Middle Neolithic period). \n" +
            "Tomb 31 is made up of four lithic slabs set in the ground, two 130cm side slabs, two 40cm end slabs and a lid slab. The tomb is surrounded by large pebbles and stones that support the structure. The tomb was nearly complexly buried and only a few centimetres were visible above ground. Inside the tomb, the deceased was in a foetal position with its head facing south. There was only one burial item placed in the tomb, a shell bracelet (Glycymeris sp.) of Mediterranean origin.\n"
    },
    rockPaintingsView: {
        title: "Rock engravings ",
        par1: "Rock engravings \n" +
            "Among the folds of rock, observe the silent stories left by people from the distant past...\n",
        par2: "A few meters north-west of the burial area, a large rocky surface emerges, covered in Rock engravings (petroglyphs), painted by individuals who lived many millenia ago. \n" +
            "The images are mostly symbolic figures such as lines, geometric figures, snakes, pointed circles and cup marks. Two pediform stand out, i.e. engraved and patterned silhouettes interpreted as feet.\n" +
            "Although dating these engravings is complex, it cannot be excluded that they are, at least in part, related to the use of the necropolis (second half of the 5th millennium BC) and to subsequent periods of human habitation (first half of the 2nd millennium BC).\n" +
            "Some comparisons are possible with engraved rocks in the Valais in Switzerland which may connect the people who lived in Vollein with populations from \n" +
            "the other side of the Alps, showing common cultural aspects and possible contact.",
        par3: "",
        par4: "",
        par5: "Vollein - Petroglyphs",
        par6: "Vollein Archeological Site: Petroglyphs",
        par7: "The large rocky surface to the west of the Necropolis has carved petroglyphs which cover a surface of approximately 5 m x 3m. \n" +
            "The chiselled (percussion technique) shapes include lines, geometric forms, snakes, pointed circles and cup shapes. If you look closely you can also see two possible representations of human feet.\n" +
            "In part they can be dated to the same period as the Necropolis (second half of the 5th millennium BC), but it cannot be excluded that they date to later settlements (2nd millennium BC. ).Similar engravings can be found on the Crête des Barmes rock, near the Valias Neolithic Necropolis in Saint-Léonard, highlighting contact and cultural similarities with settlements on the other side of the Alps.  \n",
        par8:"Ambient occlusion",
        par81: "In this version the 3D model had been interpreted using an ambient occlusion effect. This is a computer shadowing technique used to make 3D objects look more realistic by simulating the soft shadows that should naturally occur in indirect or ambient lighting. The rock engravings become more visible thanks to this effect, which increases the shadows in the engravings, making them seem deeper. ",
        par9: "Greyscale",
        par91: "In this version the chromatic detail has been removed so the model is visible only in grayscale. By removing the areas where natural light hits only shadows remain, and this helps to highlight the marks and patterns engraved on the rocky surface.  This technique is commonly used by archaeologists, historians and art historians to assist the interpretation of engravings of historical and artistic value.  ",
        par10: "Red layer",
        par101: "This 3D model, developed in postproduction, show the Vollein rock engravings highlighted in red, to help with their interpretation. To obtain this effect the texture was modified using a digital coloring technique directly on the 3D mesh. The engravings, which were chiseled into the rock, represent lines geometric figures, snakes, pointed circles, cup shapes and two possible footprints. ",
    },
    circuit: {
        title: "THE AOSTA VALLEY PREHISTORICAL CIRCUIT",
        sub1: "A project for the development of prehistorical sites",
        sub2: "Every place has a prehistoric story to tell...\n",
        par1: "The Aosta Valley Prehistorical Circuit has created an itinerary between sites that promote the material and immaterial culture of the people who lived in this Alpine region during prehistoric millennia.\n" +
            "The archaeological sites included in the Circuit are custodians of prehistorical traditions, daily activities, burial rituals, spirituality and community. At the same time these sites narrate the evolution of archaeological digs and the difficulties that archaeologists face in studying such delicate, ancient remains in harsh mountain terrain. \n" +
            "Discover the Aosta Valley Prehistoric Sites included in the Circuit as part of the project to promote the PITEM Pa.C.E.  \n",
        menu1: "Mont Fallère – Saint-Pierre",
        textmenu1: "A high-altitude area used seasonally by groups of Mesolithic hunter gathers (7th millennium BC) and by shepherds to heard livestock from the second half of the 5th millennium BC.",
        menu2: "Champrotard Necropolos  – Villeneuve",
        textmenu2: "A Neolithic Necropolis dating from the 5th millennium BC. Which has 33 Chamblandes-type cist tombs and numerous archaeological finds. The site preserves the burial rituals similar to those found in the Valais Archaeological Sites in Switzerland.",
        menu3: "Megalithic area of Saint-Martin-de-Corléans – Aosta",
        textmenu3: "Millenia of evidence of  burial rituals and agricultural activity. Human activity is evident from ploughed furrows dating from the second half of the 5th millennium BC to the 3rd millennium BC with the alignments with wooden poles, anthropomorphic stelaes, menhirs and burial monuments..",
        menu4: "The petroglyphs of Chenal – Montjovet",
        textmenu4: "Rocky walls preserve ancient memories along the path that leads to the Chenal Castle. Numerous engraved figures overlap each other, testifying to the important ritual activities of the inhabitants from the 5th millennium BC..",
        menu5: "Barma Cotze Shelter – Donnas",
        textmenu5: " A rock used as a shelter during the Mesolithic (7th millennium BC) period and then as a burial site during the 5th millennium BC. The Neolithic burials identified by archaeologists have revealed new stories to tell as the site continues to be excavated.",
        menu6: "Vollein Archaeological Site –Quart",
        textmenu6: "Burials, petroglyphs and important archaeological finds: the history of this place, inhabited from the fifth to the second millennium BC, resurfaces from the rocky earth to narrate a long history.",
        par2: "DFARM srl has carried out a study of the circuits main points of interest, providing the Aosta Valley Regional Government with a tool to collect online data, which provides real time information that than help continuously improve local tourist attraction.",
        par3: "Discover ",
        par4: "(Tourism Destination Management Multi-User Platform) ."
    },
    reperti: {
        title: "Vollein's archaeological discoveries",
        par1: "",
        par2: "The Vollein Archeological Site Collection was created as part of the project « Discover to Promote » as part of the Interreg V-A Italy-France Alcotra 2014-2020 cross-border cooperation program. 3D models of the excavation sites and significant archaeological finds allows us to promote the site using an interactive approach.\n" +
            "\n" +
            "The Vollein Archeological Site contains structures and artifacts dating from the second half of the 5th millennium BC. (Middle-Recent Neolithic period) to the 2nd millennium BC. (Bronze Age). The site is part of the Valle d'Aosta Pre-historic Circuit, a route that allows you to learn more about the main prehistoric sites in this Alpine region. \n" +
            " \n",
        rep1title: "Shell Ornaments",
        rep1desc: "Shell bracelet (Glycymeris sp.), from Tomb 31 (excavated in 1968). Middle Neolithic Period (4611- 4455 BC). Maximum dia. 13.2 cm, standard dia. 7.1 cm, width 0.9cm.\n" +
            "The use of shells as jewellery is common in Italian prehistoric sites.\n" +
            "Some of the tombs at Vollein contain pieces of clam shells bracelets (Glycymeris sp). Some of these shells have been perforated and the central part of the shell has been sanded down, while other shells have been divided into two segments and perforated at the ends. This jewellery was worn by the deceased when they were placed in their tomb. \n" +
            "Proof of the use of shell jewellery in burial rituals can also be found in other archaeological sites with Chamblandes-type tombs.\n" +
            "The presence of shells in areas far from the coast shows the Neolithic populations interest in specific materials and the existence of an effective network which allowed their distribution throughout the pan-European region.\n",
        rep2title: "Flint Lithic Industry",
        rep2desc: "Flint blade, various layers, excavation 1968. Neolithic. Length 12cm, width. 3cm.\n" +
            "The Vollein Archaeological Site provides evidence of a lithic industry based mainly on hyaline quartz, but some evidence of flint artifacts were also found.\n" +
            "Flint, a siliceous sedimentary rock, is not naturally abundant in the Aosta Valley and was therefore imported from other Alpine regions. \n" +
            "The arrowheads found at Vollein were made of flint, and were originally fixed on wooden supports and used for hunting, whereas the flint blades were used for harvesting grains and other crops. These everyday objects could have been placed in tombs as part of a burial ritual.\n",
        rep3title: "Bronze pins",
        rep3desc: "Bronze pin without tip. The cylindrical-conical pinhead is engraved with a double line surrounded by oblique dashes and the pin itself by a band of horizontal lines surrounded by a simple zig-zag pattern. Final Bronze Age (1050-950 BC). Length 10.5cm; dia. 0.3cm. \n" +
            "Pins were used to fasten draped garments at the shoulders or chest. These artifacts were mainly used by women as part of their everyday attire and more specifically during funerals.  \n" +
            "The pins found at Vollein are made of bronze, a copper and tin alloy which would have originally had a golden colour. Their green appearance today is due to the discoloration caused by their burial in a corrosive natural environment.  These shapes were made by pouring the molten metal into specially prepared moulds.\n" +
            "The shapes and decorations of Vollein brooch pins share similarities with artifacts found in noethern Italy and beyond the Alps.\n",
        rep4title: "Green stone axes",
        rep4desc: "Polished green stone axe, a unexpected find at Vollein Archaeological Site. Neolithic. Length 10.6cm, Width. max. 3.3cm, thickness. 2.2cm.\n" +
            "The polished green stone axe was used in prehistoric times for a range of everyday activities. This man made object was first chiselled or sanded down and then polished to make it smooth and sharp, then the blade was attached to a wooden handle. \n" +
            "This tool was used for cutting down trees and shrubs, a key activity for clearing farm land and to supply timber.\n" +
            "The axe could also have been used as a weapon or as an object of prestige associated with warriors.\n" +
            "These artefacts, also found in other Aosta Valley archaeological sites, are also common at sites dating to between the 6th and 2nd millennium BC across northern Italy and beyond the Alps. ",
        rep5title: "Ceramic tableware",
        rep5desc: "Ceramic bowl with grooved decorations on the outside. Middle Bronze Age 2 (1550-1450 BC). Diameter 28cm \n" +
            "Pottery was one of the most common objects found during excavations. Its endurance meant it was exploited to make containers for the preparation, consumption and conservation of food and drink. The numerous vases found at the Vollein archaeological site mainly date to the Bronze Age: the last period the site was inhabited.. The most represented shapes are the bowl and pots (olla), but there is no shortage of large vessels for storing cereals, for example. Sometimes the containers were decorated with incisions and grooves, which helps archaeologists date the artefacts. ",
    },
    credits: {
        title: "Credits",
        par1: "The 3D models, contents and tools that are part of DIGITA VOLLEIN were created for the Autonomous Region of Valle D'Aosta - Superintendence for Cultural Heritage and Activities - by a temporary grouping of companies and professionals coordinated by D-FARM srl as part of the simple project \"Discover to Promote\" of the Integrated Thematic Plan Heritage, Culture, Economy\n",
        par2: ", funded by the Interreg V-A Italy-France ALCOTRA 2014/2020 program.",
        par5: "Scientific working group: ",
        par3: "Texts edited by Natascia Druscovic with the collaboration of the scientific working group.\n",
        par4: "For info and feedback"
    },
    footer: {
        par1: "Credits",
        par2: "Copy link",
        par3: "Share"
    },
    scopri:{
        par1: "Discover AR"
    }
}