<template>
    <div class="col-12 text-center footersec">
      <img class="sovraintendenza" src="/images/credits/interreg.png" alt="">
    </div>
</template>

<script>
export default {
  name: "SecFooter"
}
</script>

<style scoped>

.sovraintendenza {
  margin-bottom: 100px;
  max-width: 300px;
  width: 100%;
}

</style>