<template >
  <nav class="navbar navbar-light fixed-top bg-brand d-flex justify-content-between">
    <a v-on:click="toggleMenu" class="burger toggle">
      <span></span>
    </a>
    <router-link class="navbar-brand" to="/">
      <!--img src="images/logoregione.png" width="30" class="d-inline-block align-top" alt=""-->
      Digita Vollein
    </router-link>
    <LangSwitcher/>
    <!--<a href="#">
      <img class="flags-lang" src="images/english.png">
    </a>-->
  </nav>
  <aside class="sidebar">
    <div class="side-inner">
      <div class="nav-menu">
        <ul>
          <li v-bind:class="{'active' : this.$route.name === 'home'}">
            <router-link v-on:click="toggleMenu" to="/"><span class="icon-home mr-3"></span>{{ $t("menu[0]") }}</router-link>
          </li>
          <li v-bind:class="{'active' : this.$route.name === 'prehistoriccircuit'}">
            <router-link v-on:click="toggleMenu" to="/prehistoriccircuit"><span class="icon-sign-out mr-3"></span>{{ $t("menu[1]") }}</router-link>
          </li>
          <li v-bind:class="{'active' : this.$route.name === 'vollein'}">
            <router-link v-on:click="toggleMenu" to="/vollein"><span class="icon-search2 mr-3"></span>{{ $t("menu[2]") }}</router-link>
          </li>
          <li v-bind:class="{'active' : this.$route.name === 'history'}">
            <router-link v-on:click="toggleMenu" to="/history"><span class="icon-notifications mr-3"></span>{{ $t("menu[3]") }}</router-link>
          </li>
          <li v-bind:class="{'active' : this.$route.name === 'rockpainting'}">
            <router-link v-on:click="toggleMenu" to="/rockpainting"><span class="icon-notifications mr-3"></span>{{ $t("menu[4]") }}</router-link>
          </li>
          <li v-bind:class="{'active' : this.$route.name === 'necropolis'}">
            <router-link v-on:click="toggleMenu" to="/necropolis"><span class="icon-notifications mr-3"></span>{{ $t("menu[5]") }}</router-link>
          </li>
          <li v-bind:class="{'active' : this.$route.name === 'finds'}">
            <router-link v-on:click="toggleMenu" to="/finds"><span class="icon-notifications mr-3"></span>{{ $t("menu[6]") }}</router-link>
          </li>
          <!--li v-bind:class="{'active' : this.$route.name === 'credits'}">
            <router-link v-on:click="toggleMenu" to="/credits"><span class="icon-notifications mr-3"></span>{{ $t("menu[7]") }}</router-link>
          </li-->
        </ul>
      </div>
    </div>
  </aside>
  <div class="menu-overlay" v-on:click="toggleMenu"></div>
</template>

<script>
import LangSwitcher from "@/components/LangSwitcher";

export default {
  name: "Menu",
  components : {
    LangSwitcher
  },
  data() {
    return {
      menu : false
    }
  },
  methods: {
    toggleMenu(){
      if(this.menu) {
        this.menu = false;
        document.querySelector('.menu-overlay').classList.remove("active");
        document.querySelector('body').classList.remove('show-sidebar');
      }else{
        this.menu = true;
        document.querySelector('.menu-overlay').classList.add("active");
        document.querySelector('body').classList.add('show-sidebar');
      }
    }
  },
}
</script>

<style scoped>

.menu-overlay{
  background: rgba(0,0,0,0.8);
  z-index: 1000;
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  display: none;
}

.menu-overlay.active{
  display: block !important;
}

.toggle{
  margin: 0 0 0 20px;
}
</style>