<template>
  <div class="site-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 ">
          <h2 class="page-title">{{ $t("vollein.title") }}</h2>
            <h4 class="page-subtitle"><i style="text-decoration: underline">{{ $t("vollein.sub1") }}</i></h4>
          <p class="text-justify">{{ $t("vollein.par1") }}</p>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-lg-12">
          <!--<video controls poster="/images/terrapiena.png">
            <source src="https://www.youtube.com/watch?v=kjEZU1JO4_U" type="video/mp4">
          </video>-->
          <div class="iframe-container">
            <iframe class="responsive-iframe" src="https://www.youtube.com/embed/CAiO7z2LPJg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <p><small>{{ $t("vollein.didascalia") }}</small></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VolleinView"
}
</script>

<style scoped>
img {
  width: 100%;
  height: 30%;
}
</style>