export const it = {
    menu: [
        "Home",
        "Circuito preistorico",
        "Vollein",
        "L'area archeologica",
        "Le incisioni rupestri",
        "La necropoli",
        "I reperti",
        "Crediti"
    ],
    home: {
        title: "LA PREISTORIA SI RACCONTA",
        sub1: "Un progetto Interreg per la valorizzazione dei siti preistorici della Valle d’Aosta",
        sub2: "Passi lenti tra le pieghe del tempo per ascoltare l’eco di voci antiche, osservare le tracce dei millenni passati e respirare il sapore della scoperta.",
        home: "I siti preistorici che testimoniano la presenza antropica in Valle d’Aosta fin dal VII millennio a.C. lasciano labili tracce disseminate su tutto il territorio.  Le caratteristiche geomorfologiche dell’ambiente montano hanno favorito il lento disfacimento delle strutture e degli oggetti creati dall’uomo nel corso dei millenni. Ciò rende sempre più complesso il lavoro degli archeologi volto alla scoperta e alla comprensione dell’evoluzione culturale delle genti che hanno vissuto in questo luogo." +
            "La presenza di attestazioni archeologiche, relative ai periodi più antichi in cui l’uomo è giunto e si è stanziato lungo i pendii, sfugge così a chi si approccia alla scoperta del territorio di questa regione alpina." +
            "Il progetto “Scoprire per promuovere/Découvrir pour promouvoir” sviluppato nel quadro del Programma di Cooperazione transfrontaliera Interreg V-A Italia-Francia Alcotra 2014-2020, mira a condividere un patrimonio comune, valorizzando l’importanza e l’unicità di questa tipologia di ritrovamenti." +
            "La Soprintendenza per i beni e le attività culturali ha così promosso e attivato azioni di salvaguardia e valorizzazione per alcuni tra i siti più significativi della Preistoria valdostana, selezionati e inseriti nel “Circuito della Preistoria in Valle d’Aosta”." +
            "La conoscenza della storia di questi luoghi permette di sviluppare maggiore sensibilità e rispetto, arricchendo ulteriormente l’esperienza della conoscenza di un territorio."
    },
    circuit: {
        title: "IL CIRCUITO DELLA PREISTORIA IN VALLE D’AOSTA",
        sub1: "Un progetto per la valorizzazione dei siti preistorici",
        sub2: "Ogni luogo ha una preistoria da raccontare...\n",
        par1: "Il “Circuito della Preistoria in Valle d’Aosta” si pone come obiettivo la creazione di un itinerario tra i luoghi più significativi che raccontino la cultura materiale e immateriale delle genti vissute in queste terre alpine nei millenni passati." +
            "I siti archeologici scelti sono custodi e testimoni di attività quotidiane, di aree dedicate al culto dei morti e alla spiritualità che ritmava il susseguirsi degli eventi delle comunità." + "Al contempo narrano l’evolversi delle tecniche di indagine e le difficoltà che gli archeologi affrontano nello studio di resti così labili come quelli preistorici presenti sul territorio montano." +
            "Scopri i siti preistorici valdostani inseriti nel Circuito della Preistoria della Valle d’Aosta del progetto Scoprire per promuovere del PITEM Pa.C.E.",
        menu1: "I siti del Mont Fallère – Saint-Pierre",
        textmenu1: "Un territorio d’alta quota sfruttato stagionalmente da gruppi di cacciatori-raccoglitori del Mesolitico antico (VII millennio a.C.) e dai pastori che, dalla seconda metà del V millennio a.C., conducevano qui il loro bestiame.",
        menu2: "La necropoli di Champrotard – Villeneuve",
        textmenu2: "Una necropoli neolitica del V millennio a.C. che, con le sue 33 tombe a cista di tipo Chamblandes e i reperti riportati alla luce, mantiene memoria dei rituali funerari svolti, simili a quelli osservati in alcuni siti archeologici del cantone svizzero del Vallese.",
        menu3: "Area megalitica di Saint-Martin-de-Corléans – Aosta",
        textmenu3: "Un luogo dove le azioni dell’uomo sono scandite nei millenni attraverso gesti e strutture rituali e funerarie che si alternano ad attività agricole. Un’area dove le tracce antropiche si osservano a partire dai solchi di aratura della seconda metà del V millennio a.C., culminando nel III millennio a.C con gli allineamenti di pali lignei, stele antropomorfe e menhir e le sepolture monumentali.",
        menu4: "Le incisioni rupestri di Chenal – Montjovet",
        textmenu4: "Lungo il sentiero che conduce al castello di Chenal, pareti rocciose custodiscono ricordi più antichi: numerose figure incise si sovrappongono tra loro, testimoniando importanti attività cultuali e rituali svolte dalle genti che fin dal V millennio a.C. hanno frequentato questo luogo.",
        menu5: "Il riparo di Barma Cotze – Donnas",
        textmenu5: "Un riparo sottoroccia sfruttato sin dal Mesolitico (VII millennio a.C.) e poi come luogo funerario durante il V millennio a.C. Le sepolture neolitiche individuate dagli archeologi celano nuove storie da raccontare e sono, in parte, ancora da scoprire.",
        menu6: "Il sito di Vollein – Quart",
        textmenu6: "Sepolture, incisioni rupestri, reperti importanti e particolari: la storia di questo luogo, vissuto nel V e nel II millennio a.C., riaffiora dalla terra e sulla roccia in un lungo racconto narrato dalle testimonianze archeologiche.",
        par2: "ha condotto uno studio dei punti di interesse che appartengono al circuito, fornendo alla Regione Val D’Aosta uno strumento di raccolta e ricognizione dei dati online salienti, la cui analisi è utile per il miglioramento continuo dell’attrattività turistica del territorio.",
        par3: "Scopri",
        par4: "(Tourism Destination Management Multi-User Platform)."
    },
    vollein: {
        title: "IL SITO ARCHEOLOGICO DI VOLLEIN",
        sub1: "Tra i millenni della Preistoria",
        par1: "Il sito archeologico di Vollein è conosciuto dal 1968, anno in cui venne segnalato alla Soprintendenza per i beni e le attività culturali della Valle d’Aosta da parte dell’allora Presidente della Société Valdôtaine de Préhistoire et d’Archéologie, Damien Daudry. \n" +
            "Dai primi sopralluoghi si comprese l’importanza del sito e numerose campagne di scavo si susseguirono fino al 1994, sotto la direzione scientifica della dott.ssa Rosanna Mollo e di Franco Mezzena.\n" +
            "Posto su di un dosso roccioso, il sito si trova a mezza quota lungo il pendio della valle centrale esposto a sud, una posizione ottimale dimostrata dallo sfruttamento dell’area fin dalla Preistoria.\n" +
            "Durante gli anni di studio vennero indagate numerose tombe che costituiscono una necropoli della seconda metà del V millennio a.C. (Neolitico medio-recente). Venne inoltre messa in luce un’ampia superficie rocciosa che presenta incisioni rupestri quali coppelle, segni lineari e possibili pediformi, in parte legate all’utilizzo della necropoli ma probabilmente anche alla frequentazione successiva. Alcuni sondaggi e ricognizioni effettuati nell’area circostante hanno riportato alla luce materiale archeologico che, insieme ai reperti presenti negli strati al di sopra delle sepolture, testimoniano la frequentazione del dosso anche durante il II millennio a.C. (Bronzo medio).\n" +
            "I lavori svolti all’interno del progetto “Scoprire per promuovere/Découvrir pour promouvoir” hanno previsto una serie di interventi sulle strutture tombali, la sistemazione e l’analisi della documentazione redatta durante le campagne di scavo, la catalogazione e lo studio preliminare del materiale archeologico e la creazione di supporti divulgativi sia in posto sia consultabili sul web (testi, video e ricostruzioni 3D).",
        didascalia: "Opera audio/visiva di Økapi dedicata alla necropoli di Vollein\n"
    },
    historyView: {
        title: "Tra i millenni della Preistoria",
        par1: "Respira l’aria di questo luogo per immergerti nelle storie che solo la terra può svelare...",
        par2: "Posto su di un dosso roccioso e dominante la valle centrale solcata dalla Dora Baltea, il sito archeologico di Vollein mette in luce diversi periodi di attività antropiche che si susseguono nei secoli tra il V e il II millennio a.C.\n" +
            "Se inizialmente l’area viene utilizzata a scopo funerario, in un secondo momento è frequentata senza una chiara destinazione d’uso.\n" +
            "Durante gli scavi archeologici il ritrovamento di diversi manufatti mostra le capacità artigianali e gli scambi tra le genti che vivevano nei territori circostanti, raccontando, seppur in modo labile, la quotidianità di chi ha vissuto questi luoghi.\n" +
            "Negli strati di terreno che hanno sigillato la necropoli e che sono stati scavati nelle altre aree indagate, oggetti di ceramica, metallo, selce e quarzo ialino testimoniano la frequentazione di Vollein durante la seconda metà del V millennio a.C. (nel così detto Neolitico medio-recente) e la prima metà del II millennio a.C. (nel periodo definito come Bronzo medio).\n",
        par3: "",
        par4: "",
        par5: "",
        par6: "",
        par7: "",
        par8: "",
        par9: "Vollein - Area",
        par10: "Il sito archeologico di Vollein : Tra i millenni della Preistoria",
        par11: "  A circa 400 m dal villaggio di Vollein, un dosso roccioso a mezza costa, lungo il versante montano\n" +
            "                esposto a sud, domina la valle centrale solcata dalla Dora Baltea.\n" +
            "                Il sito preistorico presente sulla sua sommità è conosciuto dal 1968 ed è stato oggetto di scavo\n" +
            "                archeologico e ricognizioni. oltre a sondaggi che hanno messo in luce diversi periodi di attività\n" +
            "                antropiche che si susseguono nei secoli tra il V e il II millennio a.C.\n" +
            "                Inizialmente utilizzata a scopo funerario, l’area è stata successivamente frequentata senza una\n" +
            "                chiara destinazione d’uso.\n" +
            "                Appena a nord-ovest della necropoli è stata indagata la superficie rocciosa dove sono presenti\n" +
            "                numerose incisioni rupestri che, seppur di dubbia interpretazione, sono riferibili con ogni\n" +
            "                probabilità alle diverse fasi di frequentazione dell’area.\n" +
            "                Poco a nord della necropoli, le ricerche hanno messo in luce altre sporadiche incisioni e una\n" +
            "                serie di tracce di attività umane, queste ultime riferibili al periodo definito come Bronzo medio\n" +
            "                (II millennio a.C.).",
    },
    necropolisView:{
        title: "La necropoli",
        par1: "Ascolta il silenzio di questo luogo, dove riaffiora la memoria di chi lo ha vissuto con intenso trasporto...",
        par2: "In questo avvallamento, sulla sommità del dosso roccioso, nella seconda metà del V millennio a.C. numerose sepolture hanno costituito una necropoli. \n" +
            "La struttura di queste tombe a cista litica definita di tipo Chamblandes è caratterizzata da cinque lastre di pietra: quattro poste di taglio formano una cassa rettangolare e una grande orizzontale la copertura. Parzialmente infossate nel terreno restavano a vista, permettendo di svolgere attività legate al ricordo dei defunti.\n" +
            "Nel rituale funerario generalmente il corpo veniva posto in posizione rannicchiata, o sul fianco sinistro o supino; talvolta sono presenti più corpi nella stessa tomba.\n" +
            "Rari oggetti ornamentali di corredo testimoniano gli scambi e i movimenti tra il Mediterraneo e i territori d’Oltralpe: siti svizzeri analoghi permettono tali confronti e collegamenti. È il caso del bracciale in conchiglia Glycymeris sp. trovato sul braccio dell’individuo deposto nella tomba 31.\n",
        par3: "",
        par4: "Vollein - Necropoli ",
        par5: "Il sito archeologico di Vollein : La necropoli",
        par6: "Il dosso roccioso che domina la vallata centrale della Regione ha ospitato sin dalla metà del\n" +
            "                V\n" +
            "                millennio a.C. una necropoli di oltre 50 tombe. L’area indagata è caratterizzata da\n" +
            "                un’estensione\n" +
            "                di m 30 ca. in senso est/ovest e m 20 ca. in senso nord/sud.\n" +
            "                Le sepolture messe in luce dagli archeologi sono definite tombe a cista litica di tipo\n" +
            "                Chamblandes, una tipologia di struttura funeraria diffusa tra le popolazioni neolitiche del\n" +
            "                settore occidentale delle Alpi oltre al Plateau Suisse sino al Reno.\n" +
            "                Nel rituale funerario generalmente il corpo veniva posto in posizione rannicchiata, o sul\n" +
            "                fianco\n" +
            "                sinistro o supino; talvolta sono presenti più corpi nella stessa tomba.\n" +
            "                Rari oggetti ornamentali di corredo testimoniano gli scambi e i movimenti tra il Mediterraneo\n" +
            "                e i\n" +
            "                territori d’Oltralpe: siti svizzeri analoghi permettono tali confronti e collegamenti.\n" +
            "                Pochi metri a nord-ovest dell’area sepolcrale una superficie rocciosa presenta numerose\n" +
            "                incisioni\n" +
            "                rupestri, probabilmente in relazione, almeno in parte, alla necropoli.",
        par7: "Tomba 31",
        par8: "La tomba 31 è stata messa in luce nella necropoli di Vollein (Quart, Aosta). La struttura a\n" +
            "cista litica definita di tipo Chamblandes è un’espressione funeraria caratteristica delle genti\n" +
            "che popolavano il settore occidentale delle Alpi oltre al Plateau Suisse sino al Reno, dal V\n" +
            "millennio a.C. (Neolitico medio).\n" +
            "La cassa è composta da quattro lastre litiche infisse nel terreno - due lungo i fianchi di\n" +
            "circa 130 cm e due sui lati corti di circa 40 cm - e una di copertura. La circondano grossi\n" +
            "ciottoli e pietre che costituiscono il sostegno della struttura. La struttura tombale\n" +
            "fuoriusciva dal piano di calpestio di alcuni centimetri. Lo spazio interno ospitava un defunto\n" +
            "in posizione supina con le gambe rannicchiate. L’inumato è stato deposto con il cranio a\n" +
            "sud e un solo elemento di corredo, ovvero un bracciale in conchiglia Glycymeris sp. di\n" +
            "provenienza mediterranea.\n"
    },
    rockPaintingsView: {
        title: "Le incisioni rupestri",
        par1: "Tra le pieghe della roccia, osserva queste testimoni silenziose, messaggere di racconti lasciati dalle genti di un tempo...",
        par2: "A pochi metri a nord-ovest dell’area funeraria, affiora un’ampia superficie rocciosa, mettendo in luce delle incisioni rupestri, tracce create dagli individui vissuti nei millenni passati.\n" +
            "Le immagini che si possono osservare sono per lo più figurazioni simboliche come linee, figure geometriche, serpentiformi, cerchi puntati e coppelle. Spiccano due pediformi, ovvero sagome incise e campite interpretabili come rappresentazioni di piedi.\n" +
            "Nonostante sia complesso datare queste incisioni, non si può escludere che siano, almeno in parte, in relazione all’utilizzo della necropoli (seconda metà del V millennio a.C.), ma anche alle frequentazioni successive (prima metà del II millennio a.C.).\n" +
            "Alcuni confronti sono possibili con rocce incise presenti soprattutto nel Vallese in Svizzera che collegano idealmente le genti che hanno vissuto a Vollein con le popolazioni d’Oltralpe, mostrandone i contatti e i comuni aspetti culturali.\n",
        par3: "",
        par4: "",
        par5: "Vollein - Incisioni",
        par6: "Il sito archeologico di Vollein : Le incisioni rupestri",
        par7: "Un’ampia superficie rocciosa, situata a ovest della necropoli che domina la vallata centrale\n" +
            "                solcata dalla Dora Baltea, presenta delle incisioni rupestri che si estendono per una lunghezza di\n" +
            "                m 5 ca. e per una larghezza di m 3 ca.\n" +
            "                Le figure realizzate principalmente con la tecnica della percussione, sono legate a tematiche\n" +
            "                simboliche come linee, figure geometriche, serpentiformi, cerchi puntati e coppelle. Osservando\n" +
            "                attentamente si possono vedere anche due possibili rappresentazioni di piedi.\n" +
            "                La loro datazione è incerta: se almeno in parte si possono mettere in relazione con la necropoli\n" +
            "                situata a pochi metri a sud-est (seconda metà del V millennio a.C.), non si esclude che le stesse\n" +
            "                siano riferibili alle frequentazioni successive (II millennio a.C.).\n" +
            "                Incisioni del tutto simili a quelle presenti a Vollein si trovano anche sulla roccia Crête des\n" +
            "                Barmes, nei pressi della necropoli neolitica del sito vallese di Saint-Léonard, mostrando così\n" +
            "                contatti e aspetti culturali condivisi con le popolazioni d’Oltralpe.",
        par8:"Ambient occlusion",
        par81: "In questa configurazione il modello 3D è stato trattato aumentando l’effetto dell’occlusione ambientale. L’ambient occlusion è un metodo di ombreggiatura usato nella computer grafica 3D che contribuisce a conferire realismo ai modelli di riflessione locale in quanto tiene conto dell’attenuazione luminosa in prossimità di volumi occlusi.\n" +
            "\n" +
            "Nello specifico le incisioni rupestri vengono maggiormente esaltate proprio grazie alla texture dell’occlusione ambientale che incrementa le zone d’ombra delle cavità della roccia come se queste ultime fossero più profonde.",
        par9: "Greyscale",
        par91: "In questo tipo di visualizzazione si è deciso di eliminare le infomazioni cromatiche della texture fotografica rendendo il modello visibile nella sola scala di grigi.\n" +
            "\n" +
            "In questa modalità rappresentativa vengono annullate le dominanti calde solari sulle superfici ed esaltate, al contrario le ombreaggiature rendendo dunque maggiormente evidenti le incisioni rupestri. Queste ultime, infatti, essendo “solchi” sulla superficie rocciosa risultano leggibili proprio grazie alle ombre proprie.\n" +
            "\n" +
            "Una tecnica rappresentativa molto interessante ed utilizzata anche in altri contesti per agevolare l’interpretazione di incisioni di valenza storico-artistica da parte di archeologi, storici e cultori dell’arte in senso generale.",
        par10: "Layer Rosso",
        par101:"Il modello 3D, elaborato in postproduzione, presenta le incisioni rupestri del sito archeologico di Vollein evidenziate in rosso in modo da facilitarne l’individuazione e la lettura.\n" +
            "\n" +
            "Per ottenere questo effetto la texture è stata trattata in maniera puntuale operando con una tecnica di colorazione digitale, direttamente sulla mesh 3D.\n" +
            "\n" +
            "Le figure realizzate principalmente con la tecnica della percussione, sono legate a tematiche simboliche come linee, figure geometriche, serpentiformi, cerchi puntati e coppelle. Osservando attentamente si possono vedere anche due possibili rappresentazioni di piedi."
    },
    reperti: {
        title: "Le scoperte archeologiche di Vollein",
        par1:"",
        par2: "La collezione archeologica del sito di Vollein nasce grazie al progetto semplice \"Scoprire per promuovere\" " +
            "del Piano Tematico Integrato Patrimonio, Cultura, Economia (PITEM Pa.C.E.), finanziato dal programma " +
            "Interreg V-A Francia-Italia Alcotra 2014-2020. La creazione di modelli 3D delle principali scoperte archeologiche permette " +
            "di far conoscere meglio questo sito attraverso un approccio più interattivo.\n" +
            "L'area archeologica di Vollein presenta strutture e resti che vanno dalla seconda metà del V millennio a.C. " +
            "(Neolitico medio e recente) al II millennio a.C. (Età del Bronzo), entrando così a far parte del “Circuito della Preistoria in Valle d'Aosta”," +
            " un percorso che permette di approfondire la conoscenza dei maggiori siti preistorici di questa regione alpina.\n",
        rep1title: "Gli ornamenti di conchiglia",
        rep1desc: "Bracciale di conchiglia (Glycymeris sp.), dalla Tomba 31 (scavo del 1968). Neolitico medio (4611-4455 a.C.). Diam. massimo 13,2 cm, diam. foro 7,1 cm, spess. 0,9 cm.\n" +
            "L’uso di conchiglie come ornamento è ben attestato nei siti preistorici italiani.\n" +
            "All’interno di alcune sepolture di Vollein sono state ritrovate delle valve di Glycymeris sp. lavorate al fine di ottenere dei bracciali. Alcune di queste conchiglie sono state perforate, asportando la parte centrale per abrasione, mentre altre sono state divise in due segmenti forati sulle estremità.\n" +
            "Gli oggetti così ottenuti erano indossati dai defunti nel momento della loro deposizione nella tomba.\n" +
            "L’utilizzo di monili in conchiglia, in ambito funerario, è testimoniato anche in altri siti archeologici con tombe di tipo Chamblandes.\n" +
            "La presenza di conchiglie in regioni lontane dal mare mostra l’interesse delle società neolitiche per alcuni materiali e l’esistenza di un efficace sistema di contatti che permetteva la loro diffusione in tutto il territorio paneuropeo.",
        rep2title: "L’industria litica di selce",
        rep2desc: "Lama di selce, strati vari, scavo 1968. Neolitico. Lungh. 12 cm, largh. 3 cm.\n" +
            "Nel sito archeologico di Vollein, tra i numerosi manufatti riferibili all’industria litica rappresentata soprattutto da elementi di quarzo ialino, sono stati individuati anche oggetti di selce. \n" +
            "Questa roccia sedimentaria silicea, praticamente assente dal territorio valdostano e le cui caratteristiche litologiche ne favoriscono la lavorazione e l’uso da parte dei gruppi preistorici, veniva importata da altre località dell’areale alpino. \n" +
            "Di selce erano quindi realizzate anche le cuspidi di freccia individuate a Vollein che venivano fissate su supporti di legno e utilizzate principalmente per cacciare. Le lame di selce, anch’esse immanicate su supporti di legno, erano invece utilizzate ad esempio per la mietitura dei cereali.\n" +
            "Questi oggetti di uso quotidiano potevano essere deposti come elementi di corredo all’interno delle tombe.",
        rep3title: "Gli spilloni di bronzo",
        rep3desc: "Spillone di bronzo privo della punta. La testa cilindrico-conica è decorata a bulino da doppia linea incisa marginata da trattini obliqui e il collo da fascia di linee orizzontali marginate inferiormente da zig-zag semplice. Età del Bronzo finale (1050-950 a.C.). Lungh. 10,5 cm; diam. 0,3 cm\n" +
            "Gli spilloni erano utilizzati per trattenere i lembi delle vesti drappeggiate, appuntati sulle spalle o sul petto. Questi manufatti sono spesso associati all’ambito femminile e sono attestati soprattutto in contesti funerari, ma anche domestici.\n" +
            "Gli esemplari di Vollein sono di bronzo, una lega di rame e stagno il cui colore originale è simile a quello dell’oro. L’attuale aspetto verde è dovuto alla patina che si è formata sulla loro superficie per la lunga permanenza in ambienti naturali corrosivi. La realizzazione di queste forme avveniva colando il metallo fuso in stampi appositamente predisposti.\n" +
            "Le forme e le decorazioni degli spilloni di Vollein trovano numerosi paralleli al di là delle Alpi e in Italia settentrionale.",
        rep4title: "Le asce di pietra verde",
        rep4desc: "L’ascia di pietra verde levigata, ritrovamento fortuito nell’area archeologica di Vollein. Neolitico. Lungh. 10,6 cm, largh. mass. 3,3 cm, spess. 2,2 cm. \n" +
            "L’ascia di pietra verde levigata utilizzata nella preistoria per le attività legate all’uso quotidiano era confezionata dall’uomo attraverso una serie di operazioni tra cui una prima sgrossatura e la politura accurata per rendere l’oggetto liscio e tagliente. Per ottenere un efficace strumento adatto a tagliare alberi e arbusti, l’ascia era innestata su un manico in legno.\n" +
            "Tale strumento composito era quindi impiegato per il disboscamento, operazione fondamentale per acquisire spazi coltivabili, ma anche per garantire l’approvvigionamento di legname.\n" +
            "L’ascia poteva essere utilizzata anche come arma da combattimento ovvero come oggetto di prestigio associato alla figura del guerriero.\n" +
            "Tali manufatti, rinvenuti anche su altri siti valdostani, sono ben attestati al di là delle Alpi e in Italia settentrionale tra il VI e il II millennio a.C.",
        rep5title: "Il vasellame in ceramica",
        rep5desc: "Scodella carenata in ceramica con decorazione a solcature sulla superficie esterna. Età del Bronzo Medio 2 (1550-1450 a.C.). Diam. 28 cm\n" +
            "La ceramica costituisce uno dei principali materiali archeologici rinvenuti in uno scavo. La resistenza del materiale, infatti, permise di sfruttarlo per realizzare contenitori utili alla conservazione, preparazione e consumazione di cibo e bevande.\n" +
            "I numerosi vasi messi in luce nel sito archeologico di Vollein appartengono soprattutto alle fasi più recenti della frequentazione dell’area, ossia all’età del Bronzo. Le forme più rappresentate sono la scodella e l’olla, ma non mancano vasi di grandi dimensioni per la conservazione per esempio dei cereali.\n" +
            "Talvolta i recipienti erano arricchiti da decorazioni più o meno complesse, come incisioni e scanalature, che tramandano il gusto estetico dell’epoca e contribuiscono insieme alle forme a datare gli oggetti.\n",
    },
    credits: {
        title: "Crediti",
        par1: "I modelli 3d, i contenuti e gli strumenti che fanno parte di DIGITA VOLLEIN sono stati realizzati per la Regione Autonoma Valle D’Aosta – Soprintendenza per i beni e le attività culturali - da un raggruppamento temporaneo di imprese e professionisti coordinati da D-FARM srl nell’ambito del progetto semplice “Scoprire per Promuovere” del Piano Integrato Tematico Patrimonio, Cultura, Economia\n",
        par2: ", finanziato dal programma Interreg V-A Italia-Francia ALCOTRA 2014/2020.",
        par5: "Gruppo di lavoro scientifico: ",
        par3: "Testi a cura di Natascia Druscovic con la collaborazione del gruppo di lavoro scientifico.\n",
        par4: "Per info e segnalazioni"

    },
    footer:{
        par1:"Crediti",
        par2: "Copia link",
        par3:"Condividi"
    },
    scopri:{
        par1: "Scopri AR"
    }
}