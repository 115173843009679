import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import HistoryView from '../views/HistoryView.vue'
import CreditsView from "@/views/CreditsView";
import NecropolisView from "@/views/NecropolisView";
import RockPaintingsView from "@/views/RockPaintingsView";
import CircuitView from "@/views/CircuitView";
import VolleinView from "@/views/VolleinView";
import RepertiView from "@/views/RepertiView";
import AR from "@/components/AR";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/prehistoriccircuit',
    name: 'prehistoriccircuit',
    component: CircuitView
  },
  {
    path: '/vollein',
    name: 'vollein',
    component: VolleinView
  },
  {
    path: '/history',
    name: 'history',
    component: HistoryView
  },
  {
    path: '/ar/:uid',
    name: 'ar',
    component: AR
  },
  {
    path: '/rockpainting',
    name: 'rockpainting',
    component: RockPaintingsView
  },
  {
    path: '/necropolis',
    name: 'necropolis',
    component: NecropolisView
  },
  {
    path: '/finds',
    name: 'finds',
    component: RepertiView
  },
  {
    path: '/credits',
    name: 'credits',
    component: CreditsView
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to,from, savedPosition) {
    if(savedPosition){
      return savedPosition
    }else{
      return {top: 0};
    }
  }
});

export default router
