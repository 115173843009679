<template>
  <div class="site-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h2 class="page-title" style="text-transform: uppercase;"> {{ $t("reperti.title") }} </h2>
          <h4 class="page-subtitle"><i style="text-decoration: underline">{{ $t("reperti.par1") }}</i></h4>
          <!--img class="placeholder" src="/images/placeholder-ar.avif" alt=""-->
          <p class="mt-3 mb-3 text-justify">{{ $t("reperti.par2") }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 mt-3 mb-3">
          <div class="card">
            <!--<img src="/images/3Dmodels/conchiglia.png" class="card-img-top" alt="...">-->
            <iframe class="iframe" height="400" title="Gli spilloni di bronzo" frameborder="0"
                    allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true"
                    allow="autoplay; fullscreen; xr-spatial-tracking"
                    xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered
                    web-share src="https://sketchfab.com/models/21adb7deea664016ba4b08ec8faa207d/embed">
            </iframe>
            <div class="card-body">
              <h5 class="card-title text-center "> {{ $t("reperti.rep1title") }}</h5>
              <div class="text-justify">
                {{ $t("reperti.rep1desc") }}
              </div>
              <router-link :to="'/ar/' + this.uid_rep1" class="justify-content-center d-flex">
                <button v-if="scopri" type="button" class="mt-3 button-brand">
                  {{ $t("scopri.par1") }}
                </button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 mt-3 mb-3">
          <div class="card">
            <!--<img src="/images/3Dmodels/selce.png" class="card-img-top" alt="...">-->
            <iframe class="iframe" height="400" title="Gli spilloni di bronzo" frameborder="0"
                    allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true"
                    allow="autoplay; fullscreen; xr-spatial-tracking"
                    xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered
                    web-share src="https://sketchfab.com/models/75e2c2bdad3046d2a2bb8a710d680412/embed">
            </iframe>
            <div class="card-body">
              <h5 class="card-title text-center "> {{ $t("reperti.rep2title") }}</h5>
              <div class="text-justify">
                {{ $t("reperti.rep2desc") }}
              </div>
              <router-link :to="'/ar/' + this.uid_rep2" class="justify-content-center d-flex">
                <button v-if="scopri" type="button" class="mt-3 button-brand">
                  {{ $t("scopri.par1") }}
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 mt-3 mb-3">
          <div class="card">
            <!--<img src="/images/3Dmodels/spillone.png" class="card-img-top" alt="...">-->
            <iframe class="iframe" height="400" title="Gli spilloni di bronzo" frameborder="0"
                    allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true"
                    allow="autoplay; fullscreen; xr-spatial-tracking"
                    xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered
                    web-share src="https://sketchfab.com/models/ea3c5e7a26f54057a80b38ca732f54e8/embed">
            </iframe>
            <div class="card-body">
              <h5 class="card-title text-center "> {{ $t("reperti.rep3title") }}</h5>
              <div class="text-justify">
                {{ $t("reperti.rep3desc") }}
              </div>
              <router-link :to="'/ar/' + this.uid_rep3" class="justify-content-center d-flex">
                <button v-if="scopri" type="button" class="mt-3 button-brand">
                  {{ $t("scopri.par1") }}
                </button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 mt-3 mb-3">
          <div class="card">
            <!--<img src="/images/3Dmodels/pietra_verde.png" class="card-img-top" alt="...">-->
            <iframe class="iframe" height="400" title="Gli spilloni di bronzo" frameborder="0"
                    allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true"
                    allow="autoplay; fullscreen; xr-spatial-tracking"
                    xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered
                    web-share src="https://sketchfab.com/models/eedd0ef8e32241969613a75efc66f7f9/embed">
            </iframe>
            <div class="card-body">
              <h5 class="card-title text-center "> {{ $t("reperti.rep4title") }}</h5>
              <div class="text-justify">
                {{ $t("reperti.rep4desc") }}
              </div>
              <router-link :to="'/ar/' + this.uid_rep4" class="justify-content-center d-flex">
                <button v-if="scopri" type="button" class="mt-3 button-brand">
                  {{ $t("scopri.par1") }}
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 mt-3 mb-3">
          <div class="card">
            <!--<img src="/images/3Dmodels/ceramica.png" class="card-img-top" alt="...">-->
            <iframe class="iframe" height="400" title="Gli spilloni di bronzo" frameborder="0"
                    allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true"
                    allow="autoplay; fullscreen; xr-spatial-tracking"
                    xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered
                    web-share src="https://sketchfab.com/models/3d5563ac8f4840a39d9782d12595d5c8/embed">
            </iframe>
            <div class="card-body ">
              <h5 class="card-title text-center "> {{ $t("reperti.rep5title") }}</h5>
              <div class="text-justify">
                {{ $t("reperti.rep5desc") }}
              </div>
              <router-link :to="'/ar/' + this.uid_rep5" class="justify-content-center d-flex">
                <button v-if="scopri" type="button" class="mt-3 button-brand">
                  {{ $t("scopri.par1") }}
                </button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RepertiView",
  data() {
    return {
      scopri: false,
      uid_rep1: '21adb7deea664016ba4b08ec8faa207d',
      uid_rep2: '75e2c2bdad3046d2a2bb8a710d680412',
      uid_rep3: 'ea3c5e7a26f54057a80b38ca732f54e8',
      uid_rep4: 'eedd0ef8e32241969613a75efc66f7f9',
      uid_rep5: '3d5563ac8f4840a39d9782d12595d5c8',
    }
  },
  mounted() {
    if (/Android|iPhone|Mobile/i.test(navigator.userAgent)) {
      this.scopri = true;
    }
  }
}
</script>

<style scoped>
.placeholder {
  width: 100%;
  height: 200px;
}

</style>
