<template>
  <div class="site-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h2 class="page-title">{{ $t("home.title") }}</h2>
          <h4 class="page-subtitle"><i style="text-decoration: underline">{{ $t("home.sub1") }}</i></h4>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="iframe-container">
            <iframe class="responsive-iframe" src="https://www.youtube.com/embed/EzZW0A4pFuE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <p class="mb-3 mt-3 text-justify"><i>{{ $t("home.sub2") }}</i></p>
          <p class="text-justify">{{ $t("home.home") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue';
import {BASE_API} from "@/constants/constants";

// Import Swiper styles
import 'swiper/css';

export default {
  name: 'HomeView',
  components: {
    Swiper,
    SwiperSlide
  },
  data(){
    return {
      base : BASE_API
    }
  }
}
</script>

<style scoped>
img {
  border-radius: 10px;
}

.card {
  border: none;
}

.img-container {
  display: flex;
  margin: auto;
  justify-content: center;
  width: 300px;
  height: 300px;
  overflow: hidden;
  border-radius: 50%;
}
.img-container img {
  width: auto;
  height: 100%;
}

.site-section h2, p {
  margin-bottom: 0;
}

.contheader{
  width: 100%;
  height: 200px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #dedede;
}
</style>
