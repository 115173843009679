<template>
  <div class="scroll-top" v-on:click="scrollToTop" v-if="scrolled">
    <svg class="icon-up" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
      <path d="M169.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L192 205.3 54.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/>
    </svg>
  </div>
</template>
<script>
export default {
  name: 'ScrollTop',
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  data() {
    return {
      scrolled: false
    }
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 50;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }
}
</script>
<style scoped>
.scroll-top {
  position: fixed;
  width: 50px;
  height: 50px;
  background-color: #019BAA;
  bottom: 75px;
  right: 15px;
}
.scroll-top:hover{
  cursor: pointer;
}
.icon-up {
  width: 20px;
  fill: white;
  margin: 10px 0 0 15px;
}
</style>
