<template>
  <div class="site-section">
    <div class="container mb-5">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h2 class="page-title">{{ $t("circuit.title") }}</h2>
            <h4 class="page-subtitle"><i style="text-decoration: underline">{{ $t("circuit.sub1") }}</i></h4>
          <img src="/images/mappa.png" alt="">
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <p class="mb-3 mt-3 text-justify"><i>{{ $t("circuit.sub2") }}</i></p>
          <p class="text-justify">{{ $t("circuit.par1") }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  {{
                    $t("circuit.menu1")
                  }}
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  {{ $t("circuit.textmenu1") }}</div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  {{
                    $t("circuit.menu2")
                  }}
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  {{ $t("circuit.textmenu2") }} </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  {{
                    $t("circuit.menu3")
                  }}
                </button>
              </h2>
              <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  {{ $t("circuit.textmenu3") }}  </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFour">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  {{
                    $t("circuit.menu4")
                  }}
                </button>
              </h2>
              <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  {{ $t("circuit.textmenu4") }} </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFive">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                  {{
                    $t("circuit.menu5")
                  }}
                </button>
              </h2>
              <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  {{ $t("circuit.textmenu5") }} </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingSix">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                  {{
                    $t("circuit.menu6")
                  }}
                </button>
              </h2>
              <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  {{ $t("circuit.textmenu6") }} </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-lg-6">
          <img src="/images/dfarm.png" width="60" alt="">
        </div>
        <div class="col-lg-6">
          <p> <a class="link" href="https://www.d-farm.eu/"  target="_blank"> DFARM srl </a> {{ $t("circuit.par2") }}</p>
          <p>{{ $t("circuit.par3") }} <a class="link" href="https://toumake.biz/landing" target="_blank"> TouMake2.0 </a> {{ $t("circuit.par4") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  name: 'CircuitView',
  components: {},
}

</script>

<style scoped>

img {
  width: 100%;
  max-height: 500px;
}

.arrow {
  width: 20px;
  margin: 0 0 15px 10px;
}

.accordion-button:not(.collapsed) {
  background: white;
}

.accordion-button:focus {
  box-shadow: none;
}
</style>