import { createStore } from 'vuex'
import getters from '@/services/getters';
import mutations from '@/services/mutations';
import actions from '@/services/actions';

export default createStore({
  state: {
    spinner : true
  },
  getters,
  mutations,
  actions,
  modules: {}
})
