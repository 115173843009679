export const fr = {
    menu: [
        "Home",
        "Circuit préhistorique",
        "Vollein",
        "Site archéologique",
        "Les pétroglyphes",
        "La nécropole",
        "Les trouvailles",
        "Crédits"
    ],
    home: {
        title: "LA PRÉHISTOIRE SE RACONTE",
        sub1: "Un projet Interreg pour la mise en valeur des sites préhistoriques de la Vallée d’Aoste",
        sub2: "Doucement, dans les plis du temps, pour écouter l’écho de voix anciennes, observer les traces des millénaires passés et sentir le goût de la découverte.",
        home: "Les sites préhistoriques qui témoignent de la présence anthropique en Vallée d’Aoste depuis le VIIe millénaire av. J.-C. laissent de légères traces éparpillées sur tout le territoire. Les caractéristiques géomorphologiques de l'environnement montagnard ont favorisé la lente décomposition des structures et des objets créés par l'homme au cours des millénaires passés. Cela rend de plus en plus complexe le travail des archéologues visant à la découverte et à la compréhension de l'évolution culturelle des peuples qui ont vécu dans ce lieu." +
            "La présence de témoignages archéologiques le long des pentes, relatifs aux périodes les plus anciens que l’homme ait jamais connues et où il s'est installé, échappe ainsi à ceux qui s'approchent de la découverte du territoire de cette région alpine." +
            "Dans la volonté de partager un patrimoine commun, en mettant l'accent sur l'importance et le caractère unique de ce type de découvertes, naît le projet « Scoprire per promuovere/Découvrir pour promouvoir », développé dans le cadre du Programme de Coopération transfrontalière Interreg V-A Italie-France Alcotra 2014-2020." +
            "La Surintendance des activités et des biens culturels a ainsi soutenu et mis en œuvre des actions de mise en valeur et de sauvegarde pour certains des sites les plus importants de la Préhistoire valdôtaine choisis et insérés dans le « Circuit de la Préhistoire en Vallée d’Aoste »." +
            "La compréhension de l'histoire de ces lieux permet de développer une plus grande sensibilité et un plus grand respect, en enrichissant davantage la connaissance du territoire."
    },
    circuit: {
        title: "LE CIRCUIT DE LA PRÉHISTOIRE EN VALLÉE D'AOSTE",
        sub1: "Un projet pour la mise en valeur des sites préhistoriques",
        sub2: "Chaque lieu a une préhistoire à raconter...\n",
        par1: "Le « Circuit de la Préhistoire en Vallée d’Aoste » a pour objectif la création d'un itinéraire parmi les sites les plus importants qui racontent la culture matérielle et immatérielle des peuples qui ont vécu dans ces terres alpines au cours des millénaires passés." +
            "Les sites archéologiques choisis sont des gardiens et des témoins d'activités quotidiennes, d'espaces dédiés au culte des morts et à la spiritualité qui rythmait la succession des événements des communautés." +
            "En même temps, ils racontent l’évolution des techniques d'enquête archéologique et les difficultés auxquelles les archéologues sont confrontés dans l'étude de vestiges aussi labiles que ceux préhistoriques présents sur le territoire montagnard." +
            "Découvrez les sites préhistoriques valdôtains inclus dans le Circuit de la Préhistoire de la Vallée d’Aoste dans le cadre du projet Découvrir pour promouvoir du PITEM Pa.C.E.",
        menu1: "Les sites du Mont Fallère – Saint-Pierre",
        textmenu1: "Un territoire de haute montagne exploité de façon saisonnière par des groupes de chasseurs-cueilleurs du Mésolithique ancien (VIIe millénaire av. J.-C.) et par des bergers qui, à partir de la seconde moitié du Ve millénaire av. J.-C., y conduisaient leur bétail.",
        menu2: "La nécropole de Champrotard – Villeneuve",
        textmenu2: "Une nécropole néolithique du Ve millénaire av. J.-C. qui, avec ses 33 tombes en ciste de type Chamblandes et les vestiges découverts sur le site, conserve la mémoire des rituels funéraires, similaires à ceux observés dans certains sites archéologiques du canton suisse du Valais.",
        menu3: "Site mégalithique de Saint-Martin-de-Corléans",
        textmenu3: "Un lieu où les actions de l'homme sont rythmées au cours des millénaires par des gestes et des structures rituelles et funéraires qui s'alternent aux activités agricoles. Un site où les traces d'activités anthropiques sont observées à partir des sillons de labour à l'araire de la seconde moitié du Ve millénaire av. J.-C., culminant au IIIe millénaire av. J.-C. avec l'alignement de poteaux en bois, de stèles anthropomorphes et de menhirs et les sépultures monumentales.",
        menu4: "Les gravures rupestres de Chenal – Montjovet",
        textmenu4: "Le long du sentier qui mène au château de Chenal, des murs rocheux préservent les souvenirs les plus anciens : de nombreuses figures gravées se chevauchent entre elles et témoignent d'importantes activités cultuelles et rituelles menées par les gens qui, depuis le Ve millénaire av. J.-C., ont fréquenté ce lieu.",
        menu5: "L'abri de Barma Cotze – Donnas",
        textmenu5: "Un abri souterrain exploité depuis le Mésolithique (VIIe millénaire av. J.-C.) et ensuite lieu funéraire au cours du Ve millénaire av. J.-C. Les sépultures néolithiques découvertes par les archéologues cachent de nouvelles histoires à raconter et sont, en partie, encore à découvrir.",
        menu6: "Le site de Vollein – Quart",
        textmenu6: "Sépultures, gravures rupestres, découvertes importantes et particulières : l'histoire de ce site, peuplé au Ve et au IIe millénaire av. J.-C., refait surface du sol et sur les rochers dans un long récit raconté par les vestiges archéologiques.",
        par2: " srl a réalisé une étude des principaux points d'intérêt des circuits, en fournissant au Gouvernement Régional de la Vallée d'Aoste un outil de collecte de données en ligne, qui fournit des informations en temps réel qui aident à améliorer en permanence l'attraction touristique locale.",
        par3: "Découvrir ",
        par4: "(Plateforme multi-utilisateurs de gestion des destinations touristiques) ."
    },
    vollein: {
        title: "LE SITE ARCHÉOLOGIQUE DE VOLLEIN",
        sub1: "Les millénaires de la Préhistoire",
        par1: "Le site archéologique de Vollein est connu depuis 1968, quand il a été signalé à la Surintendance des activités et des biens culturels de la Vallée d’Aoste par le Président de la Société Valdôtaine de Préhistoire et d’Archéologie, Damien Daudry. Dès les premières inspections, on a compris l’importance du site et de nombreuses campagnes de fouilles se sont succédées jusqu'en 1994, sous la direction scientifique de Dr Rosanna Mollo et de Franco Mezzena.\n" +
            "Situé sur un rocher, ce site de mi-altitude se trouve le long de la pente de la vallée centrale et est exposé plein sud : un emplacement idéal démontré par son exploitation depuis la Préhistoire.\n" +
            "Au cours des années d'étude, de nombreuses tombes, qui constituent une nécropole de la seconde moitié du Ve millénaire av. J.-C. (Néolithique moyen-récent), ont été étudiées. Il a également été mis en évidence une grande surface rocheuse qui présente des gravures rupestres telles que des coupelles, des signes linéaires et des possibles pédiformes, en partie liés à l'utilisation de la nécropole, mais probablement aussi à sa fréquentation ultérieure. Certaines enquêtes et inspections effectuées dans les environs ont mis au jour du matériel archéologique qui, avec les découvertes présentes dans les couches au-dessus des sépultures, témoignent de la fréquentation de cette butte rocheuse même au cours du IIe millénaire av. J.-C. (Bronze moyen).\n" +
            "Les travaux réalisés dans le cadre du projet « Scoprire per promuovere / Découvrir pour promouvoir » ont comporté une série d'interventions sur les structures tombales, l'aménagement et l’analyse de la documentation rédigée lors des campagnes de fouilles, le catalogage et l'étude préliminaire du matériel archéologique et la création de supports de diffusion sur place et consultables sur le web (textes, vidéos et reconstructions en 3D).",
        didascalia: "Œuvre audiovisuelle d'Økapi consacrée à la nécropole de Vollein\n"
    },

    historyView: {
        title: "PARMI LES MILLÉNAIRES DE LA PRÉHISTOIRE",
        par1: "Respirez l'air de ce lieu pour vous immerger dans des histoires que seule la terre peut révéler...",
        par2: "Situé sur un rocher et dominant la vallée centrale sillonnée par la Doire Baltée, le site archéologique de Vollein met en lumière différentes périodes d'activités anthropiques qui se succèdent au cours des siècles entre le Ve et le IIe millénaire av. J.-C." +
            "À l'origine le site était utilisé pour les pratiques funéraires, plus tard il avait une autre utilisation, pas du tout claire." +
            "Au cours des fouilles archéologiques, la découverte de plusieurs artefacts montre les capacités artisanales et les échanges avec les gens qui vivaient dans les territoires environnants en racontant, bien que de manière plutôt floue, le quotidien de ceux qui ont vécu dans ces lieux." +
            " Dans les couches de sol qui ont scellé la nécropole et qui ont été fouillées dans les autres zones étudiées, des objets en céramique, en métal, en silex et en quartz hyalin témoignent de la fréquentation de Vollein pendant la seconde moitié du Ve millénaire av. J.-C. (dans le Néolithique moyen et récent) et la première moitié du IIe millénaire av. J.-C. (dans la période définie comme le Bronze moyen).",
        par3: "",
        par4: "",
        par5: "",
        par6: "",
        par7: "",
        par8: "",
        par9: "Vollein - Site archéologique",
        par10: "Le site archéologique de Vollein: Les millénaires de la Préhistoire",
        par11: "À environ 400 m du village de Vollein, une butte rocheuse à mi-côte, située le long de la pente exposée au sud, domine la vallée centrale sillonnée par la Doire Baltée.\n" +
            "Le site préhistorique sur son sommet, connu depuis 1968, a fait l'objet de fouilles archéologiques, ainsi que d'enquêtes qui ont mis au jour différentes périodes d'activités anthropiques qui se succèdent au cours des siècles, entre le Ve et le IIe millénaire av. J.-C.\n" +
            "À l'origine il était utilisé pour les pratiques funéraires, plus tard il avait une autre utilisation, pas du tout claire. \n" +
            "À quelques mètres au nord-ouest de la nécropole, affleure une surface rocheuse, caractérisée par des nombreuses gravures rupestres qui appartiennent probablement aux différentes phases de fréquentation de ce lieu.\n" +
            "Peu au nord de la nécropole, les recherches ont mis en évidence d'autres gravures sporadiques et une série de traces d'activités humaines, ces dernières se référant à la période appelée Bronze moyen (IIe millénaire av. J.-C.).",
    },
    necropolisView: {
        title: "La nécropole",
        par1: "Écoutez le silence de ce lieu, où ceux qui l'ont vécu affleurent à la mémoire dans un élan intense...",
        par2: "Dans ce creux, au sommet de la butte rocheuse, au cours de la deuxième moitié du Ve millénaire av. J.-C. de nombreuses sépultures ont constitué une nécropole.\n" +
            "La structure de ces cistes lithiques, de type Chamblandes, est caractérisée par cinq dalles de pierre: quatre sont placées en vertical pour former un cercueil rectangulaire et une grande en horizontal qui forme la couverture. Partiellement enfoncées dans le sol, elles restaient visibles, permettant d'effectuer des activités pour rendre hommage aux défunts.\n" +
            "Lors du rite funéraire, le corps était généralement placé dans une position contractée, déposé sur le côté gauche ou sur le dos; parfois, plusieurs corps sont présents dans le même tombeau.\n" +
            "De rares objets ornementaux, comme trousseau funéraire, témoignent des échanges et des mouvements entre la Méditerranée et les territoires transalpins: des sites suisses analogues permettent de telles comparaisons et liaisons. C'est le cas du bracelet en coquille Glycymeris sp. trouvé sur le bras de l'individu déposé dans la tombe 31.",
        par3:"",
        par4: "Vollein - La nécropole",
        par5: "Le site archéologique de Vollein : La nécropole",
        par6: "La butte rocheuse surplombant la vallée centrale de la Région a abrité depuis le milieu du Ve millénaire av. J.-C. une nécropole de plus de 50 tombes. La zone étudiée couvre une surface d'environ 600 mètres carrés.\n" +
            "Les inhumations mises au jour par les archéologues sont appelées tombes en ciste de type Chamblandes, une structure funéraire répandu parmi les populations néolithiques des Alpes occidentales au-delà du Plateau Suisse jusqu'au Rhin.\n" +
            "Lors du rite funéraire, le corps était généralement placé en position contractée, déposé sur le côté gauche ou sur le dos ; parfois, plusieurs corps sont présents dans le même tombeau.\n" +
            "De rares objets ornementaux, comme trousseau funéraire, témoignent des échanges et des contacts entre la Méditerranée et les territoires transalpins : des sites suisses analogues permettent de telles comparaisons et liaisons. \n" +
            "À quelques mètres au nord-ouest de la nécropole, une surface rocheuse présente de nombreuses gravures rupestres, probablement liées, au moins en partie, à la nécropole.",
        par7: "texte en préparation",
        par8: "texte en préparation"
    },
    reperti:{
        title: "Les découvertes archéologiques de Vollein",
        par1: "",
        par2: "La collection archéologique du site de Vollein est née grâce au projet simple « Découvrir pour promouvoir » du Plan Intégré Thématique Patrimoine, Culture, Économie (PITEM Pa.C.E.), financé par le programme Interreg V-A France-Italie Alcotra 2014-2020. La création des modèles 3D des découvertes archéologiques majeures permet de mieux faire connaître ce site à travers une approche plus interactive." +
            "La zone archéologique de Vollein présente des structures et des vestiges allant de la seconde moitié du Ve millénaire av. J.-C. (Néolithique moyen et récent) au IIe millénaire av. J.-C. (âge du Bronze), en faisant ainsi partie du « Circuit de la Préhistoire en Vallée d’Aoste », un itinéraire qui permet d'approfondir la connaissance des sites préhistoriques majeurs de cette région alpine.",
        rep1title: "Parures de coquillage",
        rep1desc: "Bracelet de coquille (Glycymeris sp.), du Tombeau 31 (fouille de 1968). Néolithique moyen (4611-4455 av. J.-C.). Diam. max. 13,2 cm, diam. trou 7,1 cm, épais. 0,9 cm.\n" +
            "L’utilisation de coquillages comme ornement est bien attestée dans les sites préhistoriques italiens.\n" +
            "Des valves de Glycymeris sp. travaillées pour obtenir des bracelets ont été retrouvées à l'intérieur de certaines tombes de Vollein, quelques-unes perforées par abrasion de la partie centrale, d'autres divisées en deux segments percés aux extrémités.\n" +
            "Les objets ainsi obtenus étaient portés par les défunts au moment de leur enterrement.\n" +
            "L’utilisation de bijoux en coquille, dans le domaine funéraire, est également attestée dans d'autres sites archéologiques avec des tombes de type Chamblandes.\n" +
            "La présence de coquillages dans des régions éloignées de la mer montre l’intérêt des sociétés néolithiques pour certains matériaux et l’existence d'un système efficace de contacts permettant leur diffusion sur tout le territoire paneuropéen.",
        rep2title: "L’industrie lithique de silex",
        rep2desc: "Lame en silex, plusieurs couches, fouille de 1968. Néolithique. Long. 12 cm, larg. 3 cm.\n" +
            "Dans le site archéologique de Vollein, parmi les nombreux artefacts se rapportant à l'industrie lithique représentée principalement par des éléments de quartz hyalin, des objets en silex ont également été identifiés.\n" +
            "Cette roche sédimentaire siliceuse, pratiquement absente du territoire valdôtain et dont les caractéristiques lithologiques en favorisent la transformation et l’utilisation par les groupes préhistoriques, était importée d'autres localités de la région alpine.\n" +
            "Même les pointes de flèches identifiées à Vollein, qui étaient fixées sur des supports en bois et utilisées principalement pour chasser, étaient donc réalisées en silex. Les lames en silex, également fixées sur des supports en bois, étaient employées, par exemple, pour la moisson des céréales.\n" +
            "Ces objets d'usage quotidien pouvaient être déposés comme trousseau funéraire à l'intérieur des tombes.",
        rep3title: "Les épingles en bronze",
        rep3desc: "Épingle en bronze sans pointe. Sa tête, cylindrique et conique, est travaillée au burin et décorée d'une double ligne gravée, avec des traits obliques à marge. Son col est décoré d’une bande de lignes horizontales avec un simple zig-zag à marge. Âge du Bronze final (1050-950 av. J.-C.) Long. 10,5 cm; Diam. 0,3 cm.\n" +
            "Les épingles étaient utilisées pour retenir les lambeaux des vêtements drapés, sur les épaules ou sur la poitrine. Ces objets, souvent associés au milieu féminin, sont attestés surtout en contexte funéraire, mais aussi domestique.\n" +
            "Les exemplaires de Vollein sont en bronze, un alliage de cuivre et d'étain dont la couleur originale est similaire à celle de l'or. L’aspect vert actuel est dû à la patine qui s'est formée pour la longue permanence dans des environnements corrosifs. La réalisation de ces formes a eu lieu en coulant le métal fondu dans des moules.\n" +
            "De nombreuses formes et décorations analogues à celles des épingles de Vollein sont aussi présentes au-delà des Alpes et dans le nord de l'Italie.",
        rep4title: "Les haches en pierre verte",
        rep4desc: "La hache polie en pierre verte, découverte fortuite dans le site archéologique de Vollein. Néolithique. Long. 10,6 cm, larg. max. 3,3 cm, épais. 2,2 cm.\n" +
            "La hache polie en pierre verte utilisée dans la préhistoire pour les activités liées à la vie quotidienne était fabriquée par l'homme à travers une série d'opérations, y compris un premier dégrossissage et un polissage soigné pour rendre l’objet lisse et tranchant. Pour obtenir un outil efficace adapté à la coupe des arbres et des arbustes, la hache était fixée sur une manche en bois.\n" +
            "Cet outil composite était donc utilisé pour le déboisement, opération fondamentale pour gagner des espaces agricoles, mais aussi pour garantir l’approvisionnement en bois.\n" +
            "La hache pouvait également être utilisée comme arme de combat ou comme objet de prestige associé à la figure du guerrier.\n" +
            "Ces artefacts, qu’on retrouve sur d'autres sites valdôtains aussi, sont bien attestés au-delà des Alpes et dans le nord de l'Italie entre le VIe et le IIe millénaire av. J.-C.",
        rep5title: "La vaisselle en céramique",
        rep5desc: "Jatte carénée en céramique avec décoration à rainures sur la surface extérieure. Âge du Bronze moyen (1550-1450 av. J.-C.) Diam. 28 cm.\n" +
            "La céramique est l'un des principaux matériaux archéologiques trouvés dans les fouilles. Sa résistance, en effet, a permis de l'exploiter pour réaliser des récipients pour la conservation, la préparation et la consommation d'aliments et de boissons.\n" +
            "Les nombreux vases mis en évidence sur le site archéologique de Vollein appartiennent principalement aux phases les plus récentes de sa fréquentation, à savoir l'âge du Bronze. Les formes les plus représentées sont la jatte et le pot, mais il ne manque pas des jarres utilisées, par exemple, pour la conservation des céréales.\n" +
            "Parfois, les récipients étaient enrichis par des décorations plus ou moins complexes, telles que des gravures et des rainures, qui transmettent le goût esthétique de l'époque et contribuent, ensemble aux formes, à dater les objets.\n",
    },
    rockPaintingsView: {
        title: "Les gravures rupestres",
        par1: "Parmi les plis du rocher, observez ces témoins silencieux, messagers de récits laissés par les gens d'autrefois...",
        par2: "À quelques mètres au nord-ouest de la nécropole, affleure une large surface rocheuse, mettant en lumière des gravures rupestres, des traces crées par des individus ayant vécu au cours des millénaires passés.\n" +
            "Les images que l'on peut observer sont pour la plupart des représentations symboliques telles que des lignes, des figures géométriques et serpentiformes, des cercles pointés et des coupelles. On distingue deux pédiformes, c'est-à-dire des silhouettes gravées et colorées, susceptibles d'être interprétés comme des représentations de pieds.\n" +
            "Bien qu'il soit complexe de dater ces gravures, il ne peut être exclu qu'elles soient, au moins en partie, liées à l'utilisation de la nécropole (deuxième moitié du Ve millénaire av. J.-C.), mais aussi aux fréquentations ultérieures (première moitié du IIe millénaire av. J.-C.).\n" +
            "Certaines comparaisons sont possibles avec des roches gravées, présentes principalement dans le Valais en Suisse, qui relient idéalement les gens qui ont vécu à Vollein avec les populations transalpines, en montrant les contacts qu'elles avaient entre elles et leurs aspects culturels communs.",
        par3: "",
        par4: "",
        par5: "Vollein - Les gravures rupestres",
        par6: "Le site archéologique de Vollein : Les gravures rupestres",
        par7: "Une grande surface rocheuse, située à l'ouest de la nécropole surplombant la vallée centrale, présente des gravures rupestres sur une longueur d'environ 5 m et sur une largeur d'environ 3 m.\n" +
            "Les figures réalisées principalement par percussion sont liées à des thèmes symboliques tels que les lignes, les figures géométriques et serpentiformes, les cercles pointés et les cupules. En regardant attentivement, on distingue également deux représentations susceptibles d'être interprétés comme des pieds.\n" +
            "Leur datation est incertaine : si, au moins en partie, on peut établir un lien avec la nécropole (seconde moitié du Ve millénaire av. J.-C.), il n'est pas exclu qu'elles soient liées aux fréquentations successives (IIe millénaire av. J.-C.).\n" +
            "Des gravures similaires à celles de Vollein se trouvent sur la roche appelée Crête des Barmes, près de la nécropole néolithique du site valaisan de Saint-Léonard, ce qui prouve des contacts et des aspects culturels partagés avec les populations transalpines.",
        par8: "Occlusion ambiante",
        par81: "texte en préparation",
        par9: "Greyscale",
        par91: "texte en préparation",
        par10: "Couche rouge",
        par101: "texte en préparation",
    },
    credits: {
        title: "Credits",
        par1: " Modèles 3D, les contenus, et les outils qui font partie de DIGITA VOLLEIN ont été créés pour la Région Autonome du Val d'Aoste - Surintendance des biens et activités culturels - par un groupement temporaire d'entreprises et de professionnels coordonné par D-FARM srl dans le cadre du projet simple \"Découvrir promouvoir\" du Plan Thématique Intégré Patrimoine, Culture, Economie\n",
        par2: ", financé par le programme Interreg V-A Italie-France ALCOTRA 2014/2020.\n",
        par5: "Groupe de travail scientifique: ",
        par3: "Textes édités par Natascia Druscovic avec la collaboration de l'équipe scientifique.\n",
        par4: "Pour infos et commentaires"
    },
    footer: {
        par1: "Crédits",
        par2: "Copier le lien",
        par3: "Partager"
    },
    scopri:{
        par1: "Découvrez RA"
    }

}