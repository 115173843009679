import { createI18n } from "vue-i18n";
import {it} from "@/language/it";
import {en} from "@/language/en";
import {fr} from "@/language/fr";

const messages = {
    en: en,
    it: it,
    fr: fr
};

export default createI18n({
    locale: (localStorage.getItem('lang') || 'it'),
    fallbackLocale: ['it', 'fr', 'en'],
    legacy: false,
    globalInjection: true,
    messages
})