<template>
  <div class="switcher">
    <select class="form-control" v-model="$i18n.locale" v-on:change="changeLang">
      <option v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale">
        <template v-if="locale === 'it'">
          🇮🇹
        </template>
        <template v-if="locale === 'fr'">
          🇫🇷
        </template>
        <template v-if="locale === 'en'">
          🇬🇧
        </template>
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "LangSwitcher",
  data() {
    return {
      locales: ["it", "fr", "en"]
    };
  },
  methods : {
    changeLang(){
      localStorage.setItem("lang",this.$i18n.locale);
    }
  }
}
</script>

<style scoped>
  .switcher{
    margin: 0 5px 0 0;
  }

  .switcher select{
    background-color: transparent;
    border: 0;
    color: #ffffff;
    text-align: center !important;
  }

  .switcher select:focus, .switcher select:active {
    outline: none !important;
  }

  .switcher select, .switcher select option{
    font-size: 30px;
  }

  .switcher select:focus {
    box-shadow: none;
  }
</style>