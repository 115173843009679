<template>
  <div class="site-section">
    <div class="container">
      <div class="contain-iframe text-center" id="contain-iframe">
        <button class="button-brand" v-on:click="goBack()">Torna indietro</button>
        <iframe class="iframe" src="" id="api-frame"
                allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking
                execution-while-out-of-viewport
                execution-while-not-rendered web-share allowfullscreen mozallowfullscreen="true"
                webkitallowfullscreen="true">
        </iframe>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "AR",
  data() {
    return {
      uid: this.$route.params.uid,
    }
  },
  methods : {
    goBack(){
      this.$router.go(-1);
    }
  },
  mounted() {
    store.commit("showSpinner");
    let container = document.getElementById("contain-iframe")
    container.style.display = "block";
    container.style.visibility = "visible";
    var iframe = document.getElementById('api-frame');

    document.addEventListener('load', () => console.log('viewerready'));
    // By default, the latest version of the viewer API will be used.
    var client = new Sketchfab(iframe);

    // Alternatively, you can request a specific version.
    // var client = new Sketchfab( '1.12.0', iframe );

    client.init(this.uid, {
      success: function onSuccess(api) {
        console.log('Success');
        api.load();
        api.start();
        api.addEventListener('viewerready', function () {
          console.log('Viewer is ready');
          //Questo metodo chiama l'ar
          if (/Android|iPhone|Mobile/i.test(navigator.userAgent)) {
            api.startAR(function (err) {
              store.commit("hideSpinner");
              if (!err) {
                window.console.log('Starting AR');
              } else {
                alert("Errore nel caricamento dell'AR");
              }
            });
          }
          api.stop(function() {
            store.commit("hideSpinner");
          });
        });
      },
      error: function onError() {
        console.log(this.error);
      }
    });
  },
}
</script>

<style scoped>
.iframe {
  width: 100%;
  height: 500px;
}

@media (max-width: 768px) {
  .iframe {
    visibility: hidden;
    display: none;
  }
}
</style>