<template>
  <div class="site-section" >
    <div class="container mb-5">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h2 class="page-title" style="text-transform: uppercase;">{{ $t("historyView.title") }}</h2>
          <h4 class="page-subtitle"><i style="text-decoration: underline">{{ $t("historyView.par1") }}</i></h4>
          <!--img class="image_top" src="/images/placeholder-ar.avif" alt=""-->
          <p class="mb-3 mt-3 text-justify"> {{ $t("historyView.par2") }} </p>
          <p class="mb-3 mt-3 text-justify"> {{ $t("historyView.par3") }} </p>
          <p class="mb-3 mt-3"> {{ $t("historyView.par4") }} </p>
          <p class="mb-3 mt-3"> {{ $t("historyView.par5") }} </p>
          <p class="mb-3 mt-3"> {{ $t("historyView.par6") }} </p>
          <p class="mb-3 mt-3"> {{ $t("historyView.par7") }} </p>
          <p class="mb-3 mt-3"> {{ $t("historyView.par8") }} </p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <h5 class="text-center mb-3 mt-3"> {{ $t("historyView.par9") }} </h5>
          <div class="sketchfab-embed-wrapper">
            <iframe class="iframe" title="Gli spilloni di bronzo" frameborder="0"
                    allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true"
                    allow="autoplay; fullscreen; xr-spatial-tracking"
                    xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered
                    web-share src="https://sketchfab.com/models/6bbbc3f6a2ae4a178dc3493703a8b258/embed">
            </iframe>
          </div>
          <!--<iframe class="iframe" src="" id="api-frame"
                  allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking
                  execution-while-out-of-viewport
                  execution-while-not-rendered web-share allowfullscreen mozallowfullscreen="true"
                  webkitallowfullscreen="true">
          </iframe>-->
          <!--<img class="imgar" src="/images/3Dmodels/area.png" alt="">-->
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="text-justify mb-3 mt-3">
            <strong> {{ $t("historyView.par10") }} </strong><br>
            <p class="mb-3 mt-3">
              {{ $t("historyView.par11") }}
            </p>
          </div>
          <div class="d-flex justify-content-center discoverar">
            <router-link :to="'/ar/' + this.uid">
              <button v-if="scopri" type="button" class="mt-3 button-brand" v-on:click="loadAR()">
                {{ $t("scopri.par1") }}
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HistoryView',
  data() {
    return {
      scopri : false,
      uid: "6bbbc3f6a2ae4a178dc3493703a8b258",
    }
  },
  mounted() {
    //this.loadModel();
    if (/Android|iPhone|Mobile/i.test(navigator.userAgent)) {
      this.scopri = true;
    }
  },
  methods: {
    /*
    loadModel() {
      store.commit("showSpinner");
      var iframe = document.getElementById('api-frame');
      document.addEventListener('load', () => console.log('viewerready'));
      var client = new Sketchfab(iframe);
      client.init(this.uid, {
        success: function onSuccess(api) {
          console.log('Success');
          store.commit("hideSpinner");
          api.load();
          api.start();
          api.addEventListener('viewerready', function () {
            if (/Android|iPhone/i.test(navigator.userAgent)) {    /*console.log('Viewer is ready');    api.startAR(function (err) {    if (!err) {    window.console.log('Starting AR');    }    });
            }
          });
        }, error: function onError(callback) {
          console.log(this.error);
        }
      });
    },
     */
    /*
    loadAR() {
      store.commit("showSpinner");
      var iframe = document.getElementById('api-frame');
      document.addEventListener('load', () => console.log('viewerready'));
      var client = new Sketchfab(iframe);
      client.init(this.uid, {
        success: function onSuccess(api) {
          console.log('Success');

          api.load();
          api.start();
          api.addEventListener('viewerready', function () {
            console.log('Viewer is ready');
            api.startAR(function (err) {
              store.commit("hideSpinner");
              if (!err) {
                window.console.log('Starting AR');
              }
            });
          });
        }, error: function onError(callback) {
          console.log(this.error);
        }
      });
    },
     */
  }
}   </script>

<style scoped>
.iframe {
  width: 100%;
  height: 500px;
}

.discoverar {
  visibility: hidden;
  display: none;
}

.imgar {
  visibility: hidden;
  display: none;
}

@media (min-width: 0px) and (max-width: 976px) {
  .discoverar {
    visibility: visible;
    display: block;
  }

  .imgar {
    visibility: visible;
    display: block;
    width: 100%;
  }
}
</style>
