<template>
  <main>
    <Spinner/>
    <Menu/>
    <Toast/>
    <router-view/>
    <SecFooter/>
    <ScrollTop/>

    <Footer/>
  </main>
</template>

<script>
import Menu from '@/components/MenuComponent'
import Spinner from '@/components/Spinner'
import Footer from "@/components/Footer";
import ScrollTop from "@/components/ScrollTop";
import Toast from "@/components/Toast";
import SecFooter from "@/views/SecFooter";

export default {
  name: 'App',
  components: {
    SecFooter,
    Toast,
    Spinner,
    Menu,
    Footer,
    ScrollTop
  }
}
</script>

<style>
@font-face{
  font-family: poppins-bold;
  src: url('../public/fonts/Poppins-Bold.ttf');
}
@font-face {
  font-family: poppins-regular;
  src: url('../public/fonts/Poppins-Regular.ttf');
}
@font-face {
  font-family: poppins-thin;
  src: url('../public/fonts/Poppins-Thin.ttf');
}

a{
  color: #019baa !important;
  text-decoration: none !important;
}

body {
  background-color: #fcfcfc;
}

p {
  font-family: poppins-regular;
  color: #6b6b6b;
  font-weight: 300; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: poppins-bold;
}

a {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}
a, a:hover {
  text-decoration: none !important;
  font-family: poppins-regular}

h2 {
  font-size: 20px; }

body {
  position: relative; }
body:before {
  position: absolute;
  content: "";
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: .3s all ease-in-out;
  -o-transition: .3s all ease-in-out;
  transition: .3s all ease-in-out; }
/*
body.show-sidebar:before {
  opacity: 1;
  visibility: visible; }
*/
.site-section {
  margin: 70px 0 70px 0;
  padding: 70px 0 0 0;
}

aside, main {
  height: 100vh;
  min-height: 580px; }

aside {
  padding: 70px 0 70px 0;
  border: none;
  width: 300px;
  left: 0;
  z-index: 1005;
  position: fixed;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  background-color: #fff;
  -webkit-transition: 1s -webkit-transform cubic-bezier(0.23, 1, 0.32, 1);
  transition: 1s -webkit-transform cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: 1s transform cubic-bezier(0.23, 1, 0.32, 1);
  transition: 1s transform cubic-bezier(0.23, 1, 0.32, 1);
  transition: 1s transform cubic-bezier(0.23, 1, 0.32, 1), 1s -webkit-transform cubic-bezier(0.23, 1, 0.32, 1); }
.show-sidebar aside {
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%); }
/*
aside .toggle {
  padding-left: 18px;
  padding-top: 18px;
  position: absolute;
  right: 0;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%); }
.show-sidebar aside .toggle .burger:before, .show-sidebar aside .toggle .burger span, .show-sidebar aside .toggle .burger:after {
  background: #fff; }
.show-sidebar aside {
  -webkit-box-shadow: 10px 0 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 10px 0 30px 0 rgba(0, 0, 0, 0.1); }
 */
aside .side-inner {
  padding: 30px 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; }
aside .side-inner .profile {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border-bottom: 1px solid #efefef; }
aside .side-inner .profile img {
  width: 80px;
  margin: 0 auto 20px auto;
  border-radius: 50%; }
aside .side-inner .profile .name {
  font-size: 18px;
  margin-bottom: 0; }
aside .side-inner .profile .country {
  font-size: 14px;
  color: #cfcfcf; }
aside .side-inner .counter {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #efefef;
  text-align: center; }
aside .side-inner .counter div .number {
  display: block;
  font-size: 20px;
  color: #000; }
aside .side-inner .counter div .number-label {
  color: #cfcfcf; }
aside .side-inner .nav-menu ul, aside .side-inner .nav-menu ul li {
  padding: 0;
  margin: 0px;
  list-style: none; }
aside .side-inner .nav-menu ul li a {
  display: block;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #8b8b8b;
  position: relative;
  -webkit-transition: .3s padding-left ease;
  -o-transition: .3s padding-left ease;
  transition: .3s padding-left ease; }
aside .side-inner .nav-menu ul li a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0px;
  background-color: #216F9C;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: .3s opacity ease, .3s visibility ease, .3s width ease;
  -o-transition: .3s opacity ease, .3s visibility ease, .3s width ease;
  transition: .3s opacity ease, .3s visibility ease, .3s width ease; }
aside .side-inner .nav-menu ul li a:active, aside .side-inner .nav-menu ul li a:focus, aside .side-inner .nav-menu ul li a:hover {
  outline: none; }
aside .side-inner .nav-menu ul li a:hover {
  background: #fcfcfc;
  color: #000; }
aside .side-inner .nav-menu ul li a:hover:before {
  width: 4px;
  opacity: 1;
  visibility: visible; }
aside .side-inner .nav-menu ul li.active a {
  background: #fcfcfc;
  color: #000; }
aside .side-inner .nav-menu ul li.active a:before {
  opacity: 1;
  visibility: visible;
  width: 4px; }

main {
  width: calc(100%); }
main .post-entry {
  margin-bottom: 30px; }
main .post-entry .custom-thumbnail {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 80px;
  flex: 0 0 80px;
  margin-right: 30px; }
main .post-content h3 {
  font-size: 18px; }
main .post-content .post-meta {
  font-size: 15px;
  color: #ccc; }

/* Burger */
.burger {
  width: 28px;
  height: 32px;
  cursor: pointer;
  position: relative;
  z-index: 99;
  float: right; }

.burger:before, .burger span, .burger:after {
  width: 100%;
  height: 2px;
  display: block;
  background: #ffffff;
  border-radius: 2px;
  position: absolute;
  opacity: 1; }

.burger:before, .burger:after {
  -webkit-transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1), transform 0.35s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1);
  transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1), transform 0.35s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1);
  transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1), transform 0.35s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1), background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1);
  content: ""; }

.burger:before {
  top: 4px; }

.burger span {
  top: 15px; }

.burger:after {
  top: 26px; }

/* Hover */
.burger:hover:before {
  top: 7px; }

.burger:hover:after {
  top: 23px; }

/* Click */
.burger.active span {
  opacity: 0; }

.burger.active:before, .burger.active:after {
  top: 40%; }

.burger.active:before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=5);
  /*for IE*/ }

.burger.active:after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=-5);
  /*for IE*/ }

.burger:focus {
  outline: none;
}

.bg-brand{
  background-color: #019BAA !important;
}

.brand-color{
  color: #019BAA !important;
}

.navbar-brand{
  color: #ffffff !important;
  line-height: 45px;
  margin-right: 0 !important;
}
.button-brand, .button-white {
  height: 58px;
  border-radius: 58px;
  padding: 0 40px 0 40px;
  font-size: 16px;
  color: white;
  border: none;
  box-shadow: 2px 2px 10px lightgrey;
  font-family: poppins-regular;
}

.button-brand{
  background-image: linear-gradient(to right, #019BAA, #2D9CDB);
}
.button-white {
  background-color: #ffffff;
}

.site-section h2{
  margin-bottom: 20px;
}

.flags-lang{
  margin-right: 20px;
  width: 40px;
}

video{
  width: 100%;
  height: 100%;
  min-height: 300px;
}

.iframe-container{
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.text-justify {
  text-align: justify;
}

.page-title{
  font-size: 28px;
  text-align: center;
  margin-top: 0 !important;
  margin-bottom: 20px !important;
}

.page-subtitle{
  text-align: center;
  font-size: 15px;
  margin-top: 20px !important;
  margin-bottom: 40px !important;
}

.accordion-header .accordion-button, .accordion-button:not(.collapsed):after{
  color: #019baa !important;

}

</style>
