<template>
  <div class="site-section">
    <div class="container mb-5">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h2 class="page-title" style="text-transform: uppercase;">{{ $t("necropolisView.title") }}</h2>
          <h4 class="page-subtitle"><i style="text-decoration: underline">{{ $t("necropolisView.par1") }} </i></h4>
          <!--img class="image_top" src="/images/placeholder-ar.avif" alt=""-->
          <p class="mt-3 mb-3 text-justify">{{ $t("necropolisView.par2") }} </p>
          <p class="mt-3 mb-3 text-justify">{{ $t("necropolisView.par3") }} </p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <h5 class="text-center mb-3 mt-3"> {{ $t("necropolisView.par4") }} </h5>
          <iframe class="iframe" height="400" title="Gli spilloni di bronzo" frameborder="0"
                  allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true"
                  allow="autoplay; fullscreen; xr-spatial-tracking"
                  xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered
                  web-share src="https://sketchfab.com/models/946046ec278448a182348d4e28a7dfc0/embed">
          </iframe>
          <!--<img class="imgar" src="/images/3Dmodels/necropoli.png" alt="">-->
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="text-justify mb-3 mt-3">
            <strong> {{ $t("necropolisView.par5") }} </strong><br>
            <p class="mb-3 mt-3">
              {{ $t("necropolisView.par6") }}
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="d-flex justify-content-center discoverar">
          <router-link :to="'/ar/' + this.uid[0]">
            <button v-if="scopri" type="button" class="mt-3 button-brand" v-on:click="loadAR()">
              {{ $t("scopri.par1") }}
            </button>
          </router-link>
        </div>
      </div>
      <br><br>
      <div class="row">
        <div class="justify-content-center">
          <iframe class="iframe" height="400" title="Vollein: Tomba 31" frameborder="0" allowfullscreen mozallowfullscreen="true"
                  webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking"
                  xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered
                  web-share src="https://sketchfab.com/models/20a2da0ce5034f02ad06d05c4508515f/embed">
          </iframe>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="text-justify mb-3 mt-3">
            <strong>{{ $t("necropolisView.par7")}} </strong><br>
            <p class="mt-3 mb-3 text-justify">{{ $t("necropolisView.par8") }} </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="d-flex justify-content-center discoverar">
          <router-link :to="'/ar/' + this.uid[1]">
            <button v-if="scopri" type="button" class="mt-3 button-brand" v-on:click="loadAR()">
              {{ $t("scopri.par1") }}
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "necropolisView",
  data() {
    return {
      scopri : false,
      uid: ["946046ec278448a182348d4e28a7dfc0","20a2da0ce5034f02ad06d05c4508515f"]
    }
  },
  mounted() {
    //this.loadModel();
    if (/Android|iPhone|Mobile/i.test(navigator.userAgent)) {
      this.scopri = true;
    }
  },
  methods: {
    /*
    loadModel() {
      store.commit("showSpinner");
      var iframe = document.getElementById('api-frame');
      document.addEventListener('load', () => console.log('viewerready'));
      var client = new Sketchfab(iframe);
      client.init(this.uid, {
        success: function onSuccess(api) {
          console.log('Success');
          store.commit("hideSpinner");
          api.load();
          api.start();
          api.addEventListener('viewerready', function () {
            if (/Android|iPhone/i.test(navigator.userAgent)) {
              /*console.log('Viewer is ready');
              api.startAR(function (err) {
                if (!err) {
                  window.console.log('Starting AR');
                }
              });
            }
          });
        },
        error: function onError(callback) {
          console.log(this.error);
        }
      });
    },
    loadAR() {
      store.commit("showSpinner");
      var iframe = document.getElementById('api-frame');
      document.addEventListener('load', () => console.log('viewerready'));
      var client = new Sketchfab(iframe);
      client.init(this.uid, {
        success: function onSuccess(api) {
          console.log('Success');
          api.load();
          api.start();
          api.addEventListener('viewerready', function () {
            console.log('Viewer is ready');
            api.startAR(function (err) {
              store.commit("hideSpinner");
              if (!err) {
                window.console.log('Starting AR');
              }
            });
          });
        },
        error: function onError(callback) {
          console.log(this.error);
        }
      });
    },
     */
  },
}
</script>

<style scoped>

.image_top {
  width: 100%;
  height: 300px;
}

.iframe {
  width: 100%;
  height: 500px;
}

.discoverar {
  visibility: hidden;
  display: none;
}

@media (min-width: 0px) and (max-width: 976px) {
  .discoverar {
    visibility: visible;
    display: block;
  }
}

</style>