<template>
  <div class="site-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h2 class="page-title" style="text-transform: uppercase;"> {{ $t("rockPaintingsView.title") }} </h2>
          <h4 class="page-subtitle"><i style="text-decoration: underline">{{ $t("rockPaintingsView.par1") }}</i></h4>
          <!--img class="placeholder" src="/images/placeholder-ar.avif" alt=""-->
          <p class="mt-3 mb-3 text-justify">{{ $t("rockPaintingsView.par2") }}</p>
          <p class="mt-3 mb-3 text-justify">{{ $t("rockPaintingsView.par3") }}</p>
          <p class="mt-3 mb-3 text-justify">{{ $t("rockPaintingsView.par4") }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card">
            <!--<img src="/images/3Dmodels/incisioniScur.png" class="card-img-top " alt="...">-->
            <iframe class="iframe" height="400" title="Gli spilloni di bronzo" frameborder="0"
                    allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true"
                    allow="autoplay; fullscreen; xr-spatial-tracking"
                    xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered
                    web-share src="https://sketchfab.com/models/057c5982dbe3432797d2987426c61ed4/embed">
            </iframe>
            <div class="card-body ">
              <h5 class="card-title text-center "> {{ $t("rockPaintingsView.par5") }}</h5>
              <div class="text-justify">
                <strong> {{ $t("rockPaintingsView.par6") }}</strong><br>
                {{ $t("rockPaintingsView.par7") }}
              </div>
              <router-link :to="'/ar/' + this.uid_incisioni1">
                <button v-if="scopri" type="button" class="mt-3 button-brand">
                  {{ $t("scopri.par1") }}
                </button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <!--<img src="/images/3Dmodels/incisioniChiar.png" class="card-img-top " alt="...">-->
            <iframe class="iframe" height="400" title="Gli spilloni di bronzo" frameborder="0"
                    allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true"
                    allow="autoplay; fullscreen; xr-spatial-tracking"
                    xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered
                    web-share src="https://sketchfab.com/models/e27e1dba38bb4491b8f1607c8deee822/embed">
            </iframe>
            <div class="card-body ">
              <h5 class="card-title text-center "> {{ $t("rockPaintingsView.par8") }}</h5>
              <div class="text-justify">
                <strong>   {{ $t("rockPaintingsView.par8") }} </strong><br>
                {{ $t("rockPaintingsView.par81") }}
              </div>
              <router-link :to="'/ar/' + this.uid_incisioni2">
                <button v-if="scopri" type="button" class="mt-3 button-brand">
                  {{ $t("scopri.par1") }}
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card">
            <!--<img src="/images/3Dmodels/incisioniGrig.png" class="card-img-top " alt="...">-->
            <iframe class="iframe" height="400" title="Gli spilloni di bronzo" frameborder="0"
                    allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true"
                    allow="autoplay; fullscreen; xr-spatial-tracking"
                    xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered
                    web-share src="https://sketchfab.com/models/0eec9f5b77ab449ea8165cc36281a0e4/embed">
            </iframe>
            <div class="card-body ">
              <h5 class="card-title text-center "> {{ $t("rockPaintingsView.par9") }}</h5>
              <div class="text-justify">
                <strong>  {{ $t("rockPaintingsView.par9") }}</strong><br>
                {{ $t("rockPaintingsView.par91") }}
              </div>
              <router-link :to="'/ar/' + this.uid_incisioni3">
                <button v-if="scopri" type="button" class="mt-3 button-brand">
                  {{ $t("scopri.par1") }}
                </button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <!--<img src="/images/3Dmodels/incisioniRos.png" class="card-img-top " alt="...">-->
            <iframe class="iframe" height="400" title="Gli spilloni di bronzo" frameborder="0"
                    allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true"
                    allow="autoplay; fullscreen; xr-spatial-tracking"
                    xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered
                    web-share src="https://sketchfab.com/models/d4989c32d8e94fae9b46562877c9b8ae/embed">
            </iframe>
            <div class="card-body ">
              <h5 class="card-title text-center "> {{ $t("rockPaintingsView.par10") }}</h5>
              <div class="text-justify">
                <strong> {{ $t("rockPaintingsView.par10") }}</strong><br>
                {{ $t("rockPaintingsView.par101") }}
              </div>
              <router-link :to="'/ar/' + this.uid_incisioni4">
                <button v-if="scopri" type="button" class="mt-3 button-brand">
                  {{ $t("scopri.par1") }}
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ARView',
  data() {
    return {
      scopri: false,
      uid_incisioni1: "057c5982dbe3432797d2987426c61ed4",
      uid_incisioni2: "d4989c32d8e94fae9b46562877c9b8ae",
      uid_incisioni3: "e27e1dba38bb4491b8f1607c8deee822",
      uid_incisioni4: "0eec9f5b77ab449ea8165cc36281a0e4",
    }
  },
  mounted() {
    if (/Android|iPhone|Mobile/i.test(navigator.userAgent)) {
      this.scopri = true;
    }
  }
}
</script>

<style scoped>

img {
  width: 100%;
  height: 300px;
}

.card-title {
  color: black;
}

@media (max-width: 768px) {
  .card {
    margin-top: 20px;
  }
}

.button-brand {
  width: 200px;
  height: 50px;
}

.card-body {
  align-items: center;
  text-align: center;
}

.card {
  margin-bottom: 10px;
}
</style>