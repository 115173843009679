<template>
  <div class="site-section">
    <div class="container mb-5">
      <div class="row">
        <div class="col-lg-12">
          <h2 class="page-title"> {{ $t("credits.title") }} </h2>
          <p class="text-justify"> {{ $t("credits.par1") }} (<a href="https://www.atlasalpilatine.eu/">PITEM Pa.C.E.</a>){{ $t("credits.par2") }} </p>
          <p class="text-justify">{{ $t("credits.par4") }}: <a >scoprirevollein@gmail.com</a> </p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="credit">
            <div class="credit-image">
              <img src="/images/credits/dfarm.png" alt="">
            </div>
            <div class="credit-link">
              <a href="https://www.d-farm.eu/" target="_blank">
                D-FARM SRL – startup innovativa
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="credit">
            <div class="credit-image">
              <img src="/images/credits/filmwork.png" alt="">
            </div>
            <div class="credit-link">
              <a href="https://www.filmwork.it/" target="_blank">
                FILMWORK SRL
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="credit">
            <div class="credit-image">
              <img src="/images/credits/archimetria.png" alt="">
            </div>
            <div class="credit-link" target="_blank">
              <a href="https://www.archimetria.it/">
                ARCHIMETRIA di Danilo Prosperi
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="credit">
            <div class="credit-image">
              <img src="/images/credits/3dmetr.jpg" alt="">
            </div>
            <div class="credit-link">
              <a href="https://3dmetrica.it/" target="_blank">
                3D METRICA di Paolo Corradeghini
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="credit">
            <div class="credit-image">
              <img src="/images/credits/3darcheo.png" alt="">
            </div>
            <div class="credit-link">
              <a href="https://www.3d-archeolab.it/" target="_blank">
                3D ARCHEOLAB di Giulio Bigliardi
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="credit">
            <div class="credit-image">
              <img src="/images/credits/saturno.png" alt="">
            </div>
            <div class="credit-link">
              <a href="https://saturnoconsulting.com/" target="_blank">
                SATURNO CONSULTING SAS
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-6">
          <p>
            {{ $t("credits.par5") }}
            <br> <br>
            Gwenaël Bertocco
            <br>
            Natascia Druscovic
            <br>
            Ambra Idone
            <br>
            Francesca Martinet
            <br>
            Luca Raiteri
            <br>
            Roger Tonetti
            <br>
            Gianfranco Zidda
            <br> <br>
            {{ $t("credits.par3") }}
          </p>
        </div>
        <div class="col-lg-6 d-flex justify-content-center">
          <img class="sovraintendenza" src="/images/credits/Soprintendenza.jpg" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreditsView"
}
</script>

<style scoped>
.credit {
  margin: 30px 30px 40px 40px ;
}

.credit-link {
  margin: 30px 0;
  text-align: center;
  font-size: 12px;
}

a, a:hover {
  color: black;
  font-weight: 600;
}
.sovraintendenza {
  width: 30%;
}
.credit-image {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.credit-image img{
  width: 100%;
  max-height: 100%;
  max-width: 300px;
}

</style>